import cloneDeep from 'lodash.clonedeep';

export default defineNuxtPlugin((nuxtApp) => {
  const resetStore = ({ store }) => {
    const initialState = cloneDeep(store.$state);
    store.$reset = () => store.$patch(cloneDeep(initialState));
  };

  const pinia = nuxtApp.$pinia;
  pinia.use(resetStore);
});
