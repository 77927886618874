import instances from './instances';
import web from './web';

export default defineNuxtPlugin(() => {
  return {
    provide: {
      api: {
        web: web(instances.web),
      },
    },
  };
});
