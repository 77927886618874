export default (short, full) => {
  const products = {};

  short.forEach((item) => {
    products[item.product_group_guid] = {};
    products[item.product_group_guid].sums2 = {};
    products[item.product_group_guid].sums = {
      min: 0,
      max: 0,
    };
  });

  for (const p of full) {
    if (!products[p.product_group_guid].sums2[p.product_summ]) {
      products[p.product_group_guid].sums2[p.product_summ] = [];
    }

    if (
      p.product_summ < products[p.product_group_guid].sums.min ||
      products[p.product_group_guid].sums.min === 0
    ) {
      products[p.product_group_guid].sums.min = p.product_summ;
    }

    if (
      p.product_summ > products[p.product_group_guid].sums.max ||
      products[p.product_group_guid].sums.max === 0
    ) {
      products[p.product_group_guid].sums.max = p.product_summ;
    }

    products[p.product_group_guid].sums2[p.product_summ].push({
      product_term: p.product_term,
      product_summ: p.product_summ,
      product_payment: p.product_payment,
      product_interest_rate: p.product_interest_rate,
      product_fcl: p.product_fcl,
      product_overpayment: p.product_overpayment,
      product_payment_frequency: p.product_payment_frequency,
    });
  }

  return products;
};
