import pickBy from 'lodash.pickby';
import { acceptHMRUpdate } from 'pinia';
import { useAppStore } from './app';
import { useAuthStore } from './auth';
import { useModalStore } from './modal';
import { useNotieStore } from './notifications';
import { useValidationStore } from './validation';
import { useReferencesStore } from './references';
import dayjs from '~/helpers/dayjs';
import transformProducts from '~/helpers/transformProducts';
import mapAddress1C from '~/helpers/mapAddress1C';
import compact from '~/helpers/compact';
import filterEmpty from '~/helpers/filterEmpty';
import parseAppeal from '~/helpers/parseAppeal';
import prepare1Cvalues from '~/helpers/prepare1Cvalues';
import isNotEmpty from '~/helpers/isNotEmpty';

export const useDocumentStore = defineStore(
  'document',
  () => {
    const document_name = ref('');
    const document_guid = ref('');
    const document_text = ref('');
    const to_block_document = ref(0);
    const document_number = ref('');
    const document_date = ref('');
    const document_is_deleted = ref(0);
    const document_is_posted = ref(0);
    const document_is_blocked = ref(0);
    const request_is_posted = ref(0);
    const document_is_blocked_user_guid = ref('');
    const document_is_blocked_date_from = ref('');
    const document_comment = ref('');
    const request_comment = ref('');
    const document_date_created = ref('');
    const document_responsible_guid = ref('');
    const document_responsible_text = ref('');
    const fio = ref('');
    const phone = ref('');
    const availability_of_video = ref(-1);
    const availability_of_credit_history = ref(-1);
    const age = ref(0);
    const category_of_inappropriate_appeal_guid = ref('');
    const category_of_inappropriate_appeal_text = ref('');
    const deferred = ref([]);
    const previous_request_guid = ref('');
    const previous_request_text = ref('');
    const temp_registration_date_from = ref('');
    const temp_registration_date_to = ref('');
    const information_source_guid = ref('');
    const information_source_text = ref('');
    const loan_agent_guid = ref('');
    const loan_agent_text = ref('');
    const channel_of_receipt_guid = ref('');
    const channel_of_receipt_text = ref('');
    const category_of_complaint_guid = ref('');
    const category_of_complaint_text = ref('');
    const processing_date_from = ref('');
    const processing_date_to = ref('');
    const cityKLADR_residence_kladr_id = ref('');
    const cityKLADR_residence_region_with_type = ref('');
    const cityKLADR_residence_area_with_type = ref('');
    const cityKLADR_residence_city_with_type = ref('');
    const cityKLADR_residence_settlement_with_type = ref('');
    const cityKLADR_residence_street_with_type = ref('');
    const cityKLADR_residence_house = ref('');
    const cityKLADR_residence_house_type = ref('');
    const cityKLADR_residence_block = ref('');
    const cityKLADR_residence_block_type = ref('');
    const cityKLADR_residence_flat = ref('');
    const cityKLADR_residence_flat_type = ref('');
    const cityKLADR_residence_postal_code = ref('');
    const cityKLADR_residence_geo_lat = ref('');
    const cityKLADR_residence_geo_lon = ref('');
    const cityKLADR_registration_kladr_id = ref('');
    const cityKLADR_registration_region_with_type = ref('');
    const cityKLADR_registration_area_with_type = ref('');
    const cityKLADR_registration_city_with_type = ref('');
    const cityKLADR_registration_settlement_with_type = ref('');
    const cityKLADR_registration_street_with_type = ref('');
    const cityKLADR_registration_house = ref('');
    const cityKLADR_registration_house_type = ref('');
    const cityKLADR_registration_block = ref('');
    const cityKLADR_registration_block_type = ref('');
    const cityKLADR_registration_flat = ref('');
    const cityKLADR_registration_flat_type = ref('');
    const cityKLADR_registration_postal_code = ref('');
    const cityKLADR_registration_geo_lat = ref('');
    const cityKLADR_registration_geo_lon = ref('');
    const cityKLADR_temp_registration_kladr_id = ref('');
    const cityKLADR_temp_registration_region_with_type = ref('');
    const cityKLADR_temp_registration_area_with_type = ref('');
    const cityKLADR_temp_registration_city_with_type = ref('');
    const cityKLADR_temp_registration_settlement_with_type = ref('');
    const cityKLADR_temp_registration_street_with_type = ref('');
    const cityKLADR_temp_registration_house = ref('');
    const cityKLADR_temp_registration_house_type = ref('');
    const cityKLADR_temp_registration_block = ref('');
    const cityKLADR_temp_registration_block_type = ref('');
    const cityKLADR_temp_registration_flat = ref('');
    const cityKLADR_temp_registration_flat_type = ref('');
    const cityKLADR_temp_registration_postal_code = ref('');
    const cityKLADR_temp_registration_geo_lat = ref('');
    const cityKLADR_temp_registration_geo_lon = ref('');
    const adressKLADR_residence_kladr_id = ref('');
    const adressKLADR_residence_region_with_type = ref('');
    const adressKLADR_residence_area_with_type = ref('');
    const adressKLADR_residence_city_with_type = ref('');
    const adressKLADR_residence_settlement_with_type = ref('');
    const adressKLADR_residence_street_with_type = ref('');
    const adressKLADR_residence_house = ref('');
    const adressKLADR_residence_house_type = ref('');
    const adressKLADR_residence_block = ref('');
    const adressKLADR_residence_block_type = ref('');
    const adressKLADR_residence_flat = ref('');
    const adressKLADR_residence_flat_type = ref('');
    const adressKLADR_residence_postal_code = ref('');
    const adressKLADR_residence_geo_lat = ref('');
    const adressKLADR_residence_geo_lon = ref('');
    const adressKLADR_registration_kladr_id = ref('');
    const adressKLADR_registration_region_with_type = ref('');
    const adressKLADR_registration_area_with_type = ref('');
    const adressKLADR_registration_city_with_type = ref('');
    const adressKLADR_registration_settlement_with_type = ref('');
    const adressKLADR_registration_street_with_type = ref('');
    const adressKLADR_registration_house = ref('');
    const adressKLADR_registration_house_type = ref('');
    const adressKLADR_registration_block = ref('');
    const adressKLADR_registration_block_type = ref('');
    const adressKLADR_registration_flat = ref('');
    const adressKLADR_registration_flat_type = ref('');
    const adressKLADR_registration_postal_code = ref('');
    const adressKLADR_registration_geo_lat = ref('');
    const adressKLADR_registration_geo_lon = ref('');
    const adressKLADR_temp_registration_kladr_id = ref('');
    const adressKLADR_temp_registration_region_with_type = ref('');
    const adressKLADR_temp_registration_area_with_type = ref('');
    const adressKLADR_temp_registration_city_with_type = ref('');
    const adressKLADR_temp_registration_settlement_with_type = ref('');
    const adressKLADR_temp_registration_street_with_type = ref('');
    const adressKLADR_temp_registration_house = ref('');
    const adressKLADR_temp_registration_house_type = ref('');
    const adressKLADR_temp_registration_block = ref('');
    const adressKLADR_temp_registration_block_type = ref('');
    const adressKLADR_temp_registration_flat = ref('');
    const adressKLADR_temp_registration_flat_type = ref('');
    const adressKLADR_temp_registration_postal_code = ref('');
    const adressKLADR_temp_registration_geo_lat = ref('');
    const adressKLADR_temp_registration_geo_lon = ref('');
    const adressKLADR_work_kladr_id = ref('');
    const adressKLADR_work_region_with_type = ref('');
    const adressKLADR_work_area_with_type = ref('');
    const adressKLADR_work_city_with_type = ref('');
    const adressKLADR_work_settlement_with_type = ref('');
    const adressKLADR_work_street_with_type = ref('');
    const adressKLADR_work_house = ref('');
    const adressKLADR_work_house_type = ref('');
    const adressKLADR_work_block = ref('');
    const adressKLADR_work_block_type = ref('');
    const adressKLADR_work_flat = ref('');
    const adressKLADR_work_flat_type = ref('');
    const adressKLADR_work_postal_code = ref('');
    const adressKLADR_work_geo_lat = ref('');
    const adressKLADR_work_geo_lon = ref('');
    const is_complete = ref(0);
    const bank_card_guid = ref('');
    const bank_card_text = ref('');
    const birthday = ref('');
    const full_name = ref('');
    const last_name = ref('');
    const previous_last_name = ref('');
    const first_name = ref('');
    const secondary_name = ref('');
    const secondary_name_is_absent = ref(0);
    const birthplace = ref('');
    const mobile_phone = ref('');
    const mobile_phone_old = ref('');
    const home_phone = ref('');
    const work_phone = ref('');
    const work_company_phone = ref('');
    const work_position = ref('');
    const work_company = ref('');
    const work_mode = ref(-1);
    const work_type_guid = ref('');
    const work_type_text = ref('');
    const work_type_comment = ref('');
    const work_experience_months = ref(0);
    const work_leader_full_name = ref('');
    const work_leader_phone = ref('');
    const sex = ref(-1);
    const marital_status = ref(-1);
    const spouse_full_name = ref('');
    const spouse_phone = ref('');
    const passport_serie = ref('');
    const passport_number = ref('');
    const passport_issuedby = ref('');
    const passport_date = ref('');
    const passport_devcode = ref('');
    const passport_city = ref('');
    const passport_old_is_present = ref(-1);
    const passport_old_serie = ref('');
    const passport_old_number = ref('');
    const passport_old_issuedby = ref('');
    const passport_old_date = ref('');
    const passport_old_devcode = ref('');
    const passport_old_city = ref('');
    const purpose_of_the_loan_guid = ref('');
    const purpose_of_the_loan_text = ref('');
    const purpose_of_the_loan_comment = ref('');
    const contact_persons_1_kind_guid = ref('');
    const contact_persons_1_kind_text = ref('');
    const contact_persons_1_birthday = ref('');
    const contact_persons_1_home_phone = ref('');
    const contact_persons_1_mobile_phone = ref('');
    const contact_persons_1_full_name = ref('');
    const contact_persons_2_kind_guid = ref('');
    const contact_persons_2_kind_text = ref('');
    const contact_persons_2_birthday = ref('');
    const contact_persons_2_home_phone = ref('');
    const contact_persons_2_mobile_phone = ref('');
    const contact_persons_2_full_name = ref('');
    const contact_persons_3_kind_guid = ref('');
    const contact_persons_3_kind_text = ref('');
    const contact_persons_3_birthday = ref('');
    const contact_persons_3_home_phone = ref('');
    const contact_persons_3_mobile_phone = ref('');
    const contact_persons_3_full_name = ref('');
    const debts_presence = ref(-1);
    const debts_1_creditor = ref('');
    const debts_1_summ = ref('');
    const debts_1_frequency_of_payments_guid = ref('');
    const debts_1_frequency_of_payments_text = ref('');
    const debts_2_creditor = ref('');
    const debts_2_summ = ref('');
    const debts_2_frequency_of_payments_guid = ref('');
    const debts_2_frequency_of_payments_text = ref('');
    const debts_3_creditor = ref('');
    const debts_3_summ = ref('');
    const debts_3_frequency_of_payments_guid = ref('');
    const debts_3_frequency_of_payments_text = ref('');
    const fixed_income = ref('');
    const official_part_of_income = ref('');
    const additional_income = ref('');
    const additional_income_comment = ref('');
    const relevance_of_deferred_demand = ref(-1);
    const own_immovable_property_available = ref(-1);
    const own_movable_property_available = ref(-1);
    const duration_of_residence_years = ref('');
    const duration_of_residence_months = ref('');
    const product_group_guid = ref('ea919dbd-0ebc-11e2-ba93-50e54934e53a');
    const product_group_text = ref('');
    const product_summ = ref(0);
    const product_term = ref(0);
    const product_interest_rate = ref('');
    const product_payment = ref('');
    const product_fcl = ref('');
    const product_overpayment = ref('');
    const loan_obtaining_method_guid = ref('');
    const loan_obtaining_method_text = ref('');
    const temp_registration_dates_required = ref(0);
    const availability_of_video_required = ref(0);
    const result_of_appeal_guid = ref('');
    const result_of_appeal_text = ref('');
    const products_full = ref([]);
    const products_short = ref([]);
    const contract_conclusion_places = ref([]);
    const loan_obtaining_methods_full = ref([]);
    const loan_obtaining_methods_short = ref([]);
    const borrower_guid = ref('');
    const borrower_text = ref('');
    const borrower_is_repeated = ref(0);
    const subdivision_guid = ref('');
    const subdivision_text = ref('');
    const ex_subdivision_text = ref('');
    const subdivision_office_address_text = ref('');
    const subdivision_office_schedule_text = ref('');
    const availability_of_delivery = ref(1);
    const fields_to_check = ref([]);
    const cancellation_before_date = ref('');
    const document_base_guid = ref('');
    const document_base_text = ref('');
    const document_base_type = ref('');
    const call_caption = ref('');
    const call_guid = ref('');
    const request_date = ref('');
    const request_guid = ref('');
    const _previousAppeals = ref([]);
    const _isMerged = ref(false);
    const cityKLADR_registration_dadata = ref({});
    const cityKLADR_registration_dadata_street = ref({});
    const cityKLADR_residence_dadata = ref({});
    const cityKLADR_residence_dadata_street = ref({});
    const cityKLADR_temp_registration_dadata = ref({});
    const cityKLADR_temp_registration_dadata_street = ref({});
    const adressKLADR_work_dadata = ref({});
    const adressKLADR_work_dadata_street = ref({});
    const spouseNames = ref({ last_name: '', first_name: '', secondary_name: '' });
    const workExperienceYears = ref('');
    const workExperienceMonths = ref('');
    const hasJobAddress = ref(null);
    const hasAdditionalIncome = ref(null);
    const additionalIncomeComment = ref('');
    const lastNameChanged = ref(null);
    const bannedRequest = ref(null);
    const brand_text = ref('');
    const brand_guid = ref('');
    const avg_monthly_payment = ref('');

    const { $api } = useNuxtApp();
    const { $patch } = useDocumentStore();

    const $patch1c = (payload) => {
      $patch(prepare1Cvalues(payload));
    };

    const validationStore = useValidationStore();

    watch(fields_to_check, (nv) => {
      validationStore.validateFileds(nv);
    });

    const products = computed(() => {
      try {
        if (!products_short.value.length || !products_full.value.length) {
          return {};
        }

        return transformProducts(products_short.value, products_full.value);
      } catch (err) {
        // eslint-disable-next-line
        console.log(`products`, err);
        return {};
      }
    });

    const readyToCalculate = computed(() => {
      try {
        if (
          !product_group_guid.value ||
          !Object.keys(products.value).length ||
          !products.value[product_group_guid.value] ||
          !products.value[product_group_guid.value]?.sums ||
          !products.value[product_group_guid.value]?.sums?.min ||
          !products.value[product_group_guid.value]?.sums?.max
        ) {
          return false;
        }

        return true;
      } catch (err) {
        // eslint-disable-next-line
        console.log(err);
        return false;
      }
    });

    const availableSums = computed(() => {
      try {
        if (!readyToCalculate.value) return [];

        return Object.keys(products.value[product_group_guid.value].sums2).map((key) =>
          Number(key),
        );
      } catch (err) {
        // eslint-disable-next-line
        console.log({ readyToCalculate: readyToCalculate.value }, `availableSums`, err);
        return [];
      }
    });

    const availableTerms = computed(() => {
      try {
        if (
          !readyToCalculate.value ||
          !products.value[product_group_guid.value]?.sums2[product_summ.value]
        ) {
          return [];
        }

        return products.value[product_group_guid.value].sums2[product_summ.value].map((i) =>
          Number(i.product_term),
        );
      } catch (err) {
        // eslint-disable-next-line
        console.log({ readyToCalculate: readyToCalculate.value }, `availableTerms`, err);
        return [];
      }
    });

    const paymentDetails = computed(() => {
      try {
        if (
          !readyToCalculate.value ||
          !products.value[product_group_guid.value]?.sums2[product_summ.value]
        ) {
          return {};
        }
        return products.value[product_group_guid.value].sums2[product_summ.value].filter(
          (i) => i.product_term === product_term.value,
        )[0];
      } catch (err) {
        // eslint-disable-next-line
        console.log({ readyToCalculate: readyToCalculate.value }, `paymentDetails`, err);
        return {};
      }
    });

    const payment = computed(() => {
      try {
        if (!readyToCalculate.value || !paymentDetails.value) return '';

        return paymentDetails.value.product_payment
          ? `${paymentDetails.value.product_payment} ₽`
          : '';
      } catch (err) {
        // eslint-disable-next-line
        console.log(`payment`, err);
        return '';
      }
    });

    const overpayment = computed(() => {
      try {
        if (!readyToCalculate.value || !paymentDetails.value) return '';

        return paymentDetails.value.product_overpayment
          ? `${paymentDetails.value.product_overpayment} ₽`
          : '';
      } catch (err) {
        // eslint-disable-next-line
        console.log(`overpayment`, err);
        return '';
      }
    });

    const paymentFrequency = computed(() => {
      try {
        if (!readyToCalculate.value || !paymentDetails.value) return '';

        return paymentDetails.value.product_payment_frequency;
      } catch (err) {
        // eslint-disable-next-line
        console.log(`paymentFrequency`, err);
        return '';
      }
    });

    const percent = computed(() => {
      try {
        if (!readyToCalculate.value || !paymentDetails.value) return '';

        return paymentDetails.value.product_interest_rate;
      } catch (err) {
        // eslint-disable-next-line
        console.log(`percent`, err);
        return '';
      }
    });

    const subdivisionText = computed(() => {
      return [subdivision_text.value, ex_subdivision_text.value].filter((x) => !!x).join(', ');
    });

    const prepareSumText = (data) => {
      try {
        if (!data) return '';
        return data.trim().toLowerCase().replace('кажд.', '').replace('разово', 'дн.');
      } catch (err) {
        // eslint-disable-next-line
        console.log(err);
        return '';
      }
    };

    const setProduct = function ({ guid = '', doNotUpdateSum = false }) {
      product_group_guid.value =
        guid || product_group_guid.value || 'ea919dbd-0ebc-11e2-ba93-50e54934e53a';
      if (!doNotUpdateSum) {
        product_summ.value = availableSums.value.at(-1);
      }
      product_term.value = availableTerms.value.at(-1);
      $patch(paymentDetails.value);
    };

    const patchByDocumentFields = (payload) => {
      $patch1c(parseAppeal(payload));
    };

    const documentIsReadOnly = computed(() => {
      if (document_is_blocked.value || document_is_posted.value || request_is_posted.value) {
        return true;
      }

      return false;
    });

    const documentIsReadOnlyMessage = computed(() => {
      if (document_is_blocked.value) {
        return 'Обращение уже открыто у другого оператора';
      }
      if (document_is_posted.value) {
        return 'Обращение уже обработано';
      }
      if (request_is_posted.value) {
        return 'Заявка уже у РМ';
      }

      return '';
    });

    const cityKLADR_residence_merged = computed(() => {
      return [
        ...new Set(
          compact([
            cityKLADR_residence_region_with_type.value,
            cityKLADR_residence_city_with_type.value,
            cityKLADR_residence_settlement_with_type.value,
          ]),
        ),
      ].join(', ');
    });

    const cityKLADR_residence_merged_street = computed(() => {
      return [...new Set(compact([cityKLADR_residence_street_with_type.value]))].join(', ');
    });

    const cityKLADR_registration_merged = computed(() => {
      return [
        ...new Set(
          compact([
            cityKLADR_registration_region_with_type.value,
            cityKLADR_registration_city_with_type.value,
            cityKLADR_registration_settlement_with_type.value,
          ]),
        ),
      ].join(', ');
    });

    const cityKLADR_registration_merged_street = computed(() => {
      return [...new Set(compact([cityKLADR_registration_street_with_type.value]))].join(', ');
    });

    const cityKLADR_temp_registration_merged = computed(() => {
      return [
        ...new Set(
          compact([
            cityKLADR_temp_registration_region_with_type.value,
            cityKLADR_temp_registration_city_with_type.value,
            cityKLADR_temp_registration_settlement_with_type.value,
          ]),
        ),
      ].join(', ');
    });

    const cityKLADR_temp_registration_merged_street = computed(() => {
      return [...new Set(compact([cityKLADR_temp_registration_street_with_type.value]))].join(', ');
    });

    const adressKLADR_work_merged = computed(() => {
      return [
        ...new Set(
          compact([
            adressKLADR_work_region_with_type.value,
            adressKLADR_work_city_with_type.value,
            adressKLADR_work_settlement_with_type.value,
          ]),
        ),
      ].join(', ');
    });

    const adressKLADR_work_merged_street = computed(() => {
      return [...new Set(compact([adressKLADR_work_street_with_type.value]))].join(', ');
    });

    const GetInfo4Appeal = async ({ clearBorrowerData = false } = {}) => {
      const notieStore = useNotieStore();

      const resetNotifications = () => {
        const lastGetInfo4AppealNotification = notieStore.noties
          .filter(
            (notification) =>
              notification.timestamp === Math.max(...notieStore.noties.map((n) => n.timestamp)),
          )
          .at(-1);

        if (lastGetInfo4AppealNotification && notieStore.noties.length > 1) {
          notieStore.removeBy([
            {
              key: 'id',
              operator: '$eq',
              value: 'GetInfo4Appeal',
            },
            {
              key: 'timestamp',
              operator: '$ne',
              value: lastGetInfo4AppealNotification.timestamp,
            },
          ]);
        }
      };

      try {
        if (!cityKLADR_residence_kladr_id.value || !phone_merged.value) return {};

        const { abortController, $patch: $patchAppstore } = useAppStore();

        if (abortController) {
          abortController.abort();
          resetNotifications();
        }

        const newAbortController = new AbortController();
        $patchAppstore({
          abortController: newAbortController,
        });

        const signal = newAbortController.signal;
        const { $api } = useNuxtApp();
        const { $state } = useDocumentStore();

        if (clearBorrowerData) {
          $patch1c({
            document_date: '',
            borrower_guid: '',
            borrower_text: '',
            previous_request_guid: '',
            request_date: '',
            request_guid: '',
          });
        }

        // add notification
        notieStore.add({
          type: 'warning',
          message: 'Получение информации о продуктах и заёмщике',
          sticky: true,
          closable: false,
          id: 'GetInfo4Appeal',
          isLoading: true,
        });

        const { data } = await $api.web.GetInfo4Appeal($state, { signal });

        $patch1c(data.data);

        if (data.data.error_desc) {
          // add sticky notification
          notieStore.forcePatch({
            id: 'GetInfo4AppealError',
            sticky: true,
            type: 'error',
            message: data.data.error_desc,
            closable: false,
          });
        } else {
          $patch({ fields_to_check: [] });
          notieStore.remove({ id: 'GetInfo4AppealError' });
        }

        notieStore.remove({ id: 'GetInfo4Appeal' });

        return data.data;
      } catch (err) {
        resetNotifications();
        return {};
      }
    };

    const cloneResidenceToAddress = () => {
      const { $state } = useDocumentStore();
      const updater = {};
      mapAddress1C('cityKLADR_residence').forEach((key) => {
        updater[key.replace('cityKLADR', 'adressKLADR')] = $state[key];
      });

      $patch(updater);
    };

    const cloneAddressByPrefixes = ({ from, to, container = null }) => {
      const { $state } = useDocumentStore();
      const keys = mapAddress1C(from);
      const updater = Object.fromEntries(
        keys.map((key) => [key.replace(from, to), container ? container[key] : $state[key]]),
      );

      $patch(updater);
    };

    const cloneResidenceToRegistration = () => {
      cloneAddressByPrefixes({
        from: 'cityKLADR_residence',
        to: 'cityKLADR_registration',
      });
      cloneAddressByPrefixes({
        from: 'adressKLADR_residence',
        to: 'adressKLADR_registration',
      });

      Object.assign(cityKLADR_residence_dadata.value, cityKLADR_registration_dadata.value);
    };

    const clearKladrValues = function (prefix) {
      if (!prefix) return;
      $patch(Object.fromEntries(mapAddress1C(prefix).map((key) => [key, ''])));
    };

    const areCitiesEqual = computed(() => {
      if (!cityKLADR_registration_kladr_id.value && !cityKLADR_residence_kladr_id.value) {
        return null;
      }
      if (!cityKLADR_registration_kladr_id.value || !cityKLADR_residence_kladr_id.value) {
        return false;
      }

      if (
        !cityKLADR_registration_dadata.value.data?.kladr_id ||
        !cityKLADR_residence_dadata.value.data?.kladr_id
      ) {
        return false;
      }

      return (
        cityKLADR_registration_dadata.value.data?.kladr_id ===
        cityKLADR_residence_dadata.value.data?.kladr_id
      );
    });

    const updateNames = function () {
      if (last_name.value) {
        previous_last_name.value = last_name.value;
      }

      if (last_name.value && first_name.value && secondary_name.value) {
        fio.value = full_name.value = [
          last_name.value,
          first_name.value,
          secondary_name.value,
        ].join(' ');
      }
    };

    const updateGender = async () => {
      const { data } = await $api.web.fio({
        query: fio.value || full_name.value,
      });
      if (!data?.data || !data.data.suggestions?.length) return;
      if (!data?.data?.suggestions[0]?.data?.gender) return;

      sex.value = data.data?.suggestions[0]?.data?.gender === 'MALE' ? 1 : 0;
    };

    const updateDate = function ({
      value,
      key,
      formatFrom = 'DD.MM.YYYY',
      formatTo = 'DD.MM.YYYY 00:00:00',
    }) {
      if (dayjs(value, formatFrom, true).isValid()) {
        $patch({
          [key]: dayjs(value, formatFrom).format(formatTo),
        });
      }
    };

    const parseDate = ({ formatFrom = 'DD.MM.YYYY 00:00:00', formatTo = 'DD.MM.YYYY', value }) => {
      if (!value || !dayjs(value, formatFrom, true).isValid()) return '';
      return dayjs(value, formatFrom).format(formatTo);
    };

    const birthday_parsed = computed(() => {
      return parseDate({ value: birthday.value });
    });

    const passport_date_parsed = computed(() => {
      return parseDate({ value: passport_date.value });
    });

    const passport_old_date_parsed = computed(() => {
      return parseDate({ value: passport_old_date.value });
    });

    const phone_merged = computed(() => {
      return mobile_phone.value || phone.value;
    });

    const isValidPhone = computed(() => {
      return mobile_phone.value.length === 11;
    });

    const updatePreviousAppeals = async function ({
      search_line = '',
      patch = true,
      notify = true,
    } = {}) {
      const handler = async () => {
        try {
          const payload = pickBy(
            {
              birthday: birthday.value,
              phone_merged: phone_merged.value,
              passport_serie: passport_serie.value,
              passport_number: passport_number.value,
            },
            filterEmpty,
          );

          if (!payload.phone_merged) return;

          payload.phone = payload.phone_merged;
          delete payload.phone_merged;
          if (namesAreFilled.value) {
            payload.search_line = fio.value;
          }

          if (payload.phone.length !== 11) {
            return;
          }

          if (search_line) {
            payload.search_line = search_line;
          }

          if (passport_serie.value && passport_number.value) {
            delete payload.phone;
          }

          const { data } = await $api.web.GetPreviousAppealsRequests(payload);

          if (patch) {
            _previousAppeals.value = data.data;
          }

          return data.data;
        } catch (err) {
          // eslint-disable-next-line
          console.log(err);
        }
      };

      if (!notify) {
        return await handler();
      }

      const notieStore = useNotieStore();

      try {
        // add notification
        notieStore.add({
          type: 'warning',
          message: 'Получение информации о предыдущих заявках',
          sticky: true,
          closable: false,
          id: 'updatePreviousAppeals',
          isLoading: true,
        });
        return await handler();
      } finally {
        // remove notification
        notieStore.remove({
          id: 'updatePreviousAppeals',
        });
      }
    };

    const specifyAppeal = async function (payload) {
      try {
        // update document by parsed fields
        $patch1c(parseAppeal(payload));

        // get info about residence city (e.g. coordinates)
        const { data } = await $api.web.address({
          query: cityKLADR_residence_merged.value,
          count: 1,
        });

        const res = data.data.suggestions[0].data;

        // update geo-data
        if (res.geo_lat && res.geo_lon) {
          cityKLADR_residence_postal_code.value = res.postal_code;
          cityKLADR_residence_geo_lat.value = res.geo_lat;
          cityKLADR_residence_geo_lon.value = res.geo_lon;
          adressKLADR_residence_geo_lat.value = res.geo_lat;
          adressKLADR_residence_geo_lon.value = res.geo_lon;
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err);
      }
    };

    const namesAreFilled = computed(() => {
      try {
        const names = fio.value || full_name.value;
        return names.replace(/\s+/g, ' ').trim().split(' ').length === 3;
      } catch (err) {
        // eslint-disable-next-line
        console.log(err);
        return false;
      }
    });

    const handleNames = async ({ getInfoForAppeal = true, names = true, gender = true } = {}) => {
      if (names) {
        updateNames();
      }

      if (getInfoForAppeal && namesAreFilled.value) {
        await GetInfo4Appeal({ clearBorrowerData: true });
      }

      if (gender) {
        await updateGender();
      }
    };

    const setTaskResult = async (payload) => {
      if (!payload.result_of_calltask_guid || !payload.document_guid) {
        throw new Error('Произошла непредвиденная ошибка');
      }
      return await $api.web.SaveDocumentData({
        payload,
        document_name: 'ЗаданиеНаОбзвон',
      });
    };

    const setAppealResult = async function (result_of_appeal_guid) {
      if (!result_of_appeal_guid) {
        throw new Error('Произошла непредвиденная ошибка');
      }

      const referencesStore = useReferencesStore();

      if (
        referencesStore.categoriesOfNonTargetedAppeals.find(
          (item) => item.guid === result_of_appeal_guid,
        )
      ) {
        $patch({
          result_of_appeal_guid: '8e5236d6-4cf0-46b0-943f-e85f157f3d35',
          category_of_inappropriate_appeal_guid: result_of_appeal_guid,
        });
      } else {
        $patch({
          result_of_appeal_guid,
        });
      }

      await $api.web.SaveDocumentData({
        payload: getPreSavedDocument(),
      });

      // return & redirect
    };

    const postpone = async () => {
      const authStore = useAuthStore();
      const modalStore = useModalStore();
      const route = useRoute();

      result_of_appeal_guid.value = '728fa48f-e848-4257-8544-30ae3145125b';

      deferred.value.push({
        date_of_chime: modalStore.postponement.date_of_chime || '01.01.0101 00:00:00',
        operator_guid: authStore.user.user_guid,
        pending_state_guid: modalStore.postponement.guid,
        comment: modalStore.postponement.comment,
        call_guid: route.query.call_guid,
        utc: modalStore.postponement.utc || 7,
      });

      await $api.web.SaveDocumentData({
        payload: getPreSavedDocument(),
      });

      // return & redirect
    };

    const getPreSavedDocument = function () {
      const authStore = useAuthStore();
      const route = useRoute();
      const { $state } = useDocumentStore();

      return {
        ...pickBy(
          $state,
          (value, key) =>
            ![
              'bannedRequest',
              'additionalIncomeComment',
              'hasAdditionalIncome',
              'hasJobAddress',
              'lastNameChanged',
              'spouseNames',
              'workExperienceMonths',
              'workExperienceYears',
              '_isMerged',
              '_previousAppeals',
              'adressKLADR_work_dadata',
              'adressKLADR_work_dadata_street',
              'cityKLADR_registration_dadata',
              'cityKLADR_registration_dadata_street',
              'cityKLADR_residence_dadata',
              'cityKLADR_residence_dadata_street',
              'cityKLADR_temp_registration_dadata',
              'cityKLADR_temp_registration_dadata_street',
              'fields_to_check',
              'result_of_appeal_text',
            ].includes(key) && isNotEmpty(value),
        ),
        passport_city: '-',
        availability_of_delivery: 0,
        document_responsible_guid: authStore?.user?.user_guid,
        call_guid: route.query.call_guid,
        passport_old_is_present: passport_old_serie.value ? 1 : 0,
      };
    };

    const isEmptyResidence = computed(() => {
      try {
        return Object.keys(cityKLADR_residence_dadata.value.data).length < 1;
      } catch (err) {
        return true;
      }
    });

    const isEmptyComplaint = computed(() => {
      const modalStore = useModalStore();

      try {
        return (
          modalStore.complaint.guid === 'b0488e87-4cfd-11e3-9422-3440b59fff32' &&
          !category_of_complaint_guid.value
        );
      } catch (err) {
        return true;
      }
    });

    const lastBannedPreviousRequest = computed(() => {
      return _previousAppeals.value
        .filter(
          (item) =>
            item.denial_is_non_refundable ||
            item.request_status === 'Займ выдан' ||
            (item.suspension_period && item.suspension_period > 0),
        )
        .sort((a, b) => new Date(b.request_date) - new Date(a.request_date))
        .at(0);
    });

    const searchBannedRequest = async function () {
      const target = [last_name.value, first_name.value, secondary_name.value]
        .join(' ')
        .replace(/\s+/g, ' ')
        .trim();

      if (!lastBannedPreviousRequest.value.fio || !target) return null;

      bannedRequest.value = null;

      const { data } = await $api.web.GetSimilarNames({
        source: lastBannedPreviousRequest.value.fio,
        target,
      });

      if (!data?.data?.result) return;

      const res = await updatePreviousAppeals({
        search_line: data.data.result,
        patch: false,
        notify: false,
      });

      if (!res) return;

      bannedRequest.value = lastBannedPreviousRequest.value;

      return !!bannedRequest.value;
    };

    const getDadataContainerByValue = async ({ query, prefix }) => {
      const { data } = await $api.web.address({ query });
      if (!data?.data || !data.data?.suggestions) return;

      $patch({
        [prefix + '_dadata']: data.data.suggestions.at(0),
      });
    };

    const getPreviousAppealsByPhone = async () => {
      const notieStore = useNotieStore();
      try {
        notieStore.add({
          type: 'warning',
          message: 'Получение информации о предыдущих заявках',
          sticky: true,
          closable: false,
          id: 'getPreviousAppealsByPhone',
          isLoading: true,
        });

        const { data } = await $api.web.GetPreviousAppealsRequests({
          phone: phone_merged.value,
          return_only_requests: 1,
        });

        _previousAppeals.value = data.data;
      } finally {
        notieStore.remove({
          id: 'getPreviousAppealsByPhone',
        });
      }
    };

    const updateCoordsByPrefix = ({ prefix, data }) => {
      $patch({
        [prefix + '_geo_lat']: data.lat,
        [prefix + '_geo_lon']: data.lon,
      });
    };

    return {
      $patch1c,
      document_name,
      document_guid,
      document_text,
      to_block_document,
      document_number,
      document_date,
      document_is_deleted,
      document_is_posted,
      document_is_blocked,
      request_is_posted,
      document_is_blocked_user_guid,
      document_is_blocked_date_from,
      document_comment,
      request_comment,
      document_date_created,
      document_responsible_guid,
      document_responsible_text,
      fio,
      phone,
      availability_of_video,
      availability_of_credit_history,
      age,
      category_of_inappropriate_appeal_guid,
      category_of_inappropriate_appeal_text,
      deferred,
      previous_request_guid,
      previous_request_text,
      temp_registration_date_from,
      temp_registration_date_to,
      information_source_guid,
      information_source_text,
      loan_agent_guid,
      loan_agent_text,
      channel_of_receipt_guid,
      channel_of_receipt_text,
      category_of_complaint_guid,
      category_of_complaint_text,
      processing_date_from,
      processing_date_to,
      cityKLADR_residence_kladr_id,
      cityKLADR_residence_region_with_type,
      cityKLADR_residence_area_with_type,
      cityKLADR_residence_city_with_type,
      cityKLADR_residence_settlement_with_type,
      cityKLADR_residence_street_with_type,
      cityKLADR_residence_house,
      cityKLADR_residence_house_type,
      cityKLADR_residence_block,
      cityKLADR_residence_block_type,
      cityKLADR_residence_flat,
      cityKLADR_residence_flat_type,
      cityKLADR_residence_postal_code,
      cityKLADR_residence_geo_lat,
      cityKLADR_residence_geo_lon,
      cityKLADR_registration_kladr_id,
      cityKLADR_registration_region_with_type,
      cityKLADR_registration_area_with_type,
      cityKLADR_registration_city_with_type,
      cityKLADR_registration_settlement_with_type,
      cityKLADR_registration_street_with_type,
      cityKLADR_registration_house,
      cityKLADR_registration_house_type,
      cityKLADR_registration_block,
      cityKLADR_registration_block_type,
      cityKLADR_registration_flat,
      cityKLADR_registration_flat_type,
      cityKLADR_registration_postal_code,
      cityKLADR_registration_geo_lat,
      cityKLADR_registration_geo_lon,
      cityKLADR_temp_registration_kladr_id,
      cityKLADR_temp_registration_region_with_type,
      cityKLADR_temp_registration_area_with_type,
      cityKLADR_temp_registration_city_with_type,
      cityKLADR_temp_registration_settlement_with_type,
      cityKLADR_temp_registration_street_with_type,
      cityKLADR_temp_registration_house,
      cityKLADR_temp_registration_house_type,
      cityKLADR_temp_registration_block,
      cityKLADR_temp_registration_block_type,
      cityKLADR_temp_registration_flat,
      cityKLADR_temp_registration_flat_type,
      cityKLADR_temp_registration_postal_code,
      cityKLADR_temp_registration_geo_lat,
      cityKLADR_temp_registration_geo_lon,
      adressKLADR_residence_kladr_id,
      adressKLADR_residence_region_with_type,
      adressKLADR_residence_area_with_type,
      adressKLADR_residence_city_with_type,
      adressKLADR_residence_settlement_with_type,
      adressKLADR_residence_street_with_type,
      adressKLADR_residence_house,
      adressKLADR_residence_house_type,
      adressKLADR_residence_block,
      adressKLADR_residence_block_type,
      adressKLADR_residence_flat,
      adressKLADR_residence_flat_type,
      adressKLADR_residence_postal_code,
      adressKLADR_residence_geo_lat,
      adressKLADR_residence_geo_lon,
      adressKLADR_registration_kladr_id,
      adressKLADR_registration_region_with_type,
      adressKLADR_registration_area_with_type,
      adressKLADR_registration_city_with_type,
      adressKLADR_registration_settlement_with_type,
      adressKLADR_registration_street_with_type,
      adressKLADR_registration_house,
      adressKLADR_registration_house_type,
      adressKLADR_registration_block,
      adressKLADR_registration_block_type,
      adressKLADR_registration_flat,
      adressKLADR_registration_flat_type,
      adressKLADR_registration_postal_code,
      adressKLADR_registration_geo_lat,
      adressKLADR_registration_geo_lon,
      adressKLADR_temp_registration_kladr_id,
      adressKLADR_temp_registration_region_with_type,
      adressKLADR_temp_registration_area_with_type,
      adressKLADR_temp_registration_city_with_type,
      adressKLADR_temp_registration_settlement_with_type,
      adressKLADR_temp_registration_street_with_type,
      adressKLADR_temp_registration_house,
      adressKLADR_temp_registration_house_type,
      adressKLADR_temp_registration_block,
      adressKLADR_temp_registration_block_type,
      adressKLADR_temp_registration_flat,
      adressKLADR_temp_registration_flat_type,
      adressKLADR_temp_registration_postal_code,
      adressKLADR_temp_registration_geo_lat,
      adressKLADR_temp_registration_geo_lon,
      adressKLADR_work_kladr_id,
      adressKLADR_work_region_with_type,
      adressKLADR_work_area_with_type,
      adressKLADR_work_city_with_type,
      adressKLADR_work_settlement_with_type,
      adressKLADR_work_street_with_type,
      adressKLADR_work_house,
      adressKLADR_work_house_type,
      adressKLADR_work_block,
      adressKLADR_work_block_type,
      adressKLADR_work_flat,
      adressKLADR_work_flat_type,
      adressKLADR_work_postal_code,
      adressKLADR_work_geo_lat,
      adressKLADR_work_geo_lon,
      is_complete,
      bank_card_guid,
      bank_card_text,
      birthday,
      full_name,
      last_name,
      previous_last_name,
      first_name,
      secondary_name,
      secondary_name_is_absent,
      birthplace,
      mobile_phone,
      mobile_phone_old,
      home_phone,
      work_phone,
      work_company_phone,
      work_position,
      work_company,
      work_mode,
      work_type_guid,
      work_type_text,
      work_type_comment,
      work_experience_months,
      work_leader_full_name,
      work_leader_phone,
      sex,
      marital_status,
      spouse_full_name,
      spouse_phone,
      passport_serie,
      passport_number,
      passport_issuedby,
      passport_date,
      passport_devcode,
      passport_city,
      passport_old_is_present,
      passport_old_serie,
      passport_old_number,
      passport_old_issuedby,
      passport_old_date,
      passport_old_devcode,
      passport_old_city,
      purpose_of_the_loan_guid,
      purpose_of_the_loan_text,
      purpose_of_the_loan_comment,
      contact_persons_1_kind_guid,
      contact_persons_1_kind_text,
      contact_persons_1_birthday,
      contact_persons_1_home_phone,
      contact_persons_1_mobile_phone,
      contact_persons_1_full_name,
      contact_persons_2_kind_guid,
      contact_persons_2_kind_text,
      contact_persons_2_birthday,
      contact_persons_2_home_phone,
      contact_persons_2_mobile_phone,
      contact_persons_2_full_name,
      contact_persons_3_kind_guid,
      contact_persons_3_kind_text,
      contact_persons_3_birthday,
      contact_persons_3_home_phone,
      contact_persons_3_mobile_phone,
      contact_persons_3_full_name,
      debts_presence,
      debts_1_creditor,
      debts_1_summ,
      debts_1_frequency_of_payments_guid,
      debts_1_frequency_of_payments_text,
      debts_2_creditor,
      debts_2_summ,
      debts_2_frequency_of_payments_guid,
      debts_2_frequency_of_payments_text,
      debts_3_creditor,
      debts_3_summ,
      debts_3_frequency_of_payments_guid,
      debts_3_frequency_of_payments_text,
      fixed_income,
      official_part_of_income,
      additional_income,
      additional_income_comment,
      relevance_of_deferred_demand,
      own_immovable_property_available,
      own_movable_property_available,
      duration_of_residence_years,
      duration_of_residence_months,
      product_group_guid,
      product_group_text,
      product_summ,
      product_term,
      product_interest_rate,
      product_payment,
      product_fcl,
      product_overpayment,
      loan_obtaining_method_guid,
      loan_obtaining_method_text,
      temp_registration_dates_required,
      availability_of_video_required,
      result_of_appeal_guid,
      result_of_appeal_text,
      products_full,
      products_short,
      contract_conclusion_places,
      loan_obtaining_methods_full,
      loan_obtaining_methods_short,
      borrower_guid,
      borrower_text,
      borrower_is_repeated,
      subdivision_guid,
      subdivision_text,
      ex_subdivision_text,
      subdivision_office_address_text,
      subdivision_office_schedule_text,
      availability_of_delivery,
      fields_to_check,
      cancellation_before_date,
      document_base_guid,
      document_base_text,
      document_base_type,
      call_caption,
      call_guid,
      _previousAppeals,
      _isMerged,
      cityKLADR_registration_dadata,
      cityKLADR_registration_dadata_street,
      cityKLADR_residence_dadata,
      cityKLADR_residence_dadata_street,
      cityKLADR_temp_registration_dadata,
      cityKLADR_temp_registration_dadata_street,
      adressKLADR_work_dadata,
      adressKLADR_work_dadata_street,
      spouseNames,
      workExperienceYears,
      workExperienceMonths,
      hasJobAddress,
      hasAdditionalIncome,
      additionalIncomeComment,
      lastNameChanged,
      bannedRequest,
      products,
      availableSums,
      availableTerms,
      paymentDetails,
      payment,
      overpayment,
      paymentFrequency,
      percent,
      subdivisionText,
      setProduct,
      readyToCalculate,
      prepareSumText,
      patchByDocumentFields,
      documentIsReadOnly,
      documentIsReadOnlyMessage,
      cityKLADR_residence_merged,
      cityKLADR_registration_merged,
      cityKLADR_temp_registration_merged,
      GetInfo4Appeal,
      // GetInfo4AppealRaw,
      cloneResidenceToAddress,
      clearKladrValues,
      cityKLADR_residence_merged_street,
      cityKLADR_registration_merged_street,
      cityKLADR_temp_registration_merged_street,
      cloneAddressByPrefixes,
      areCitiesEqual,
      cloneResidenceToRegistration,
      updateNames,
      updateGender,
      updateDate,
      birthday_parsed,
      phone_merged,
      updatePreviousAppeals,
      passport_date_parsed,
      passport_old_date_parsed,
      adressKLADR_work_merged,
      adressKLADR_work_merged_street,
      specifyAppeal,
      namesAreFilled,
      handleNames,
      setTaskResult,
      getPreSavedDocument,
      isValidPhone,
      postpone,
      isEmptyResidence,
      setAppealResult,
      isEmptyComplaint,
      lastBannedPreviousRequest,
      searchBannedRequest,
      request_date,
      request_guid,
      getDadataContainerByValue,
      getPreviousAppealsByPhone,
      updateCoordsByPrefix,
      brand_text,
      brand_guid,
      avg_monthly_payment,
    };
  },
  {
    persist: true,
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDocumentStore, import.meta.hot));
}
