export default (prefix) => {
  const keys = [
    'kladr_id',
    'region_with_type',
    'area_with_type',
    'city_with_type',
    'settlement_with_type',
    'street_with_type',
    'house',
    'house_type',
    'block',
    'block_type',
    'flat',
    'flat_type',
    'postal_code',
    'geo_lat',
    'geo_lon',
  ];

  return keys.map((key) => `${prefix}_${key}`);
};
