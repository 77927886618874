import { capitalize } from 'vue';
import { useNotieStore } from './notifications';
import dayjs from '~/helpers/dayjs';

const initialState = {
  age: false,
  cityKLADR_residence: false,
  cityKLADR_registration: false,
  cityKLADR_temp_registration: false,
  temp_registration_date_from: false,
  temp_registration_date_to: false,
  document_is_blocked: false,
  document_is_posted: false,
  request_is_posted: false,
  availability_of_video: false,
  availability_of_credit_history: false,
  adressKLADR_residence_street: false,
  adressKLADR_residence_house: false,
  debts_1_creditor: false,
  debts_2_creditor: false,
  debts_3_creditor: false,
  debts_1_summ: false,
  debts_2_summ: false,
  debts_3_summ: false,
  first_name: false,
  last_name: false,
  secondary_name: false,
  previous_last_name: false,
  birthday: false,
  birthplace: false,
  passport_serie: false,
  passport_number: false,
  passport_date: false,
  passport_old_serie: false,
  passport_old_number: false,
  passport_old_date: false,
  passport_old_is_present: false,
  contact_persons_1_kind_text: false,
  contact_persons_2_kind_text: false,
  relevance_of_deferred_demand: false,
};

export const useValidationStore = defineStore(
  'validation',
  () => {
    const notieStore = useNotieStore();

    const initialStateToRefs = () => {
      return Object.fromEntries(
        Object.keys(initialState).map((key) => [[key], ref(initialState[key])]),
      );
    };

    const fail = function (keys) {
      for (const key of keys) {
        this[key] = true;
      }
    };

    const ok = function (keys) {
      for (const key of keys) {
        this[key] = false;
        notieStore.remove({ id: `validation${capitalize(key)}` });
      }
    };

    const okAll = function () {
      for (const key of Object.keys(initialState)) {
        this[key] = false;
      }
    };

    const validateDate = function ({ key = '', value, format = 'DD.MM.YYYY' }) {
      if (dayjs(value, format, true).isValid()) {
        if (key) this.ok([key]);
        return true;
      }

      if (key) this.fail([key]);
      return false;
    };

    const validateFileds = function (fields) {
      if (fields.length) {
        this.fail(fields);
      } else {
        this.okAll();
      }
    };

    return {
      ...initialStateToRefs(),
      fail,
      ok,
      okAll,
      validateDate,
      validateFileds,
    };
  },
  {
    persist: false,
  },
);
