import isNumber from 'lodash.isnumber';
import isDate from 'lodash.isdate';
import isEmpty from 'lodash.isempty';
import isNaN from 'lodash.isnan';

export default (prop) => {
  if (prop == null || isNaN(prop)) {
    return false;
  } else if (isNumber(prop) || isDate(prop)) {
    return true;
  } else if (!isEmpty(prop)) {
    return true;
  } else if (prop === '') {
    return false;
  } else return false;
};
