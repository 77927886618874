<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>
import { useAppStore } from '~/stores/app';
import { useModalStore } from '~/stores/modal';

const appStore = useAppStore();
const modals = useModalStore();
const route = useRoute();

const overflowClass = computed(() => {
  return {
    class: modals.hasVisibleModals || route.path === '/login' ? 'overflow-hidden' : 'overflow-auto',
  };
});

useHead({
  htmlAttrs: {
    'data-theme': appStore.appTheme,
    class: appStore.appTheme,
  },
  bodyAttrs: () => overflowClass.value,
  title: 'АРМ Сотрудника ЦОК',
});
</script>
