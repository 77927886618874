import { default as createyuywxUUkQlMeta } from "/app/pages/create.vue?macro=true";
import { default as deferredhtQ14v7gmPMeta } from "/app/pages/deferred.vue?macro=true";
import { default as finishvsyUPTjZ1zMeta } from "/app/pages/finish.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as redirectvHLxODTt5rMeta } from "/app/pages/redirect.vue?macro=true";
import { default as settingsLwEYOlkQxrMeta } from "/app/pages/settings.vue?macro=true";
export default [
  {
    name: createyuywxUUkQlMeta?.name ?? "create",
    path: createyuywxUUkQlMeta?.path ?? "/create",
    meta: createyuywxUUkQlMeta || {},
    alias: createyuywxUUkQlMeta?.alias || [],
    redirect: createyuywxUUkQlMeta?.redirect || undefined,
    component: () => import("/app/pages/create.vue").then(m => m.default || m)
  },
  {
    name: deferredhtQ14v7gmPMeta?.name ?? "deferred",
    path: deferredhtQ14v7gmPMeta?.path ?? "/deferred",
    meta: deferredhtQ14v7gmPMeta || {},
    alias: deferredhtQ14v7gmPMeta?.alias || [],
    redirect: deferredhtQ14v7gmPMeta?.redirect || undefined,
    component: () => import("/app/pages/deferred.vue").then(m => m.default || m)
  },
  {
    name: finishvsyUPTjZ1zMeta?.name ?? "finish",
    path: finishvsyUPTjZ1zMeta?.path ?? "/finish",
    meta: finishvsyUPTjZ1zMeta || {},
    alias: finishvsyUPTjZ1zMeta?.alias || [],
    redirect: finishvsyUPTjZ1zMeta?.redirect || undefined,
    component: () => import("/app/pages/finish.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login",
    path: loginhHM0vSTW5jMeta?.path ?? "/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect || undefined,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: redirectvHLxODTt5rMeta?.name ?? "redirect",
    path: redirectvHLxODTt5rMeta?.path ?? "/redirect",
    meta: redirectvHLxODTt5rMeta || {},
    alias: redirectvHLxODTt5rMeta?.alias || [],
    redirect: redirectvHLxODTt5rMeta?.redirect || undefined,
    component: () => import("/app/pages/redirect.vue").then(m => m.default || m)
  },
  {
    name: settingsLwEYOlkQxrMeta?.name ?? "settings",
    path: settingsLwEYOlkQxrMeta?.path ?? "/settings",
    meta: settingsLwEYOlkQxrMeta || {},
    alias: settingsLwEYOlkQxrMeta?.alias || [],
    redirect: settingsLwEYOlkQxrMeta?.redirect || undefined,
    component: () => import("/app/pages/settings.vue").then(m => m.default || m)
  }
]