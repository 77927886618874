export default (source) => {
  const fio = source.replace(/\s+/g, ' ').trim().split(' ');

  if (fio.length && fio.length < 4) {
    if (fio.length === 3) {
      return {
        last_name: fio[0],
        first_name: fio[1],
        secondary_name: fio[2],
      };
    } else if (fio.length === 2) {
      return {
        last_name: fio[0],
        first_name: fio[1],
      };
    } else {
      return {
        first_name: fio[0],
      };
    }
  }

  return { first_name: fio };
};
