import dayjs from './dayjs';

export default (data) => {
  const isString = (value) => Object.prototype.toString.call(value) === '[object String]';

  const handler = (value) => {
    if (
      ['00.01.0101', '0001-01-01T00:00:00', '00000000-0000-0000-0000-000000000000'].includes(value)
    ) {
      return '';
    }
    if (isString(value) && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/)) {
      return dayjs(value, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY 00:00:00');
    }

    return value;
  };

  const res = {};

  Object.keys(data).forEach((key) => {
    res[key] = handler(data[key]);
  });

  return res;
};
