export const additionalIncomeOptionsSource = [
  { name: 'Соц выплаты (пенсии, пособия, стидендия)' },
  { name: 'Сдача в аренду имущества' },
  { name: 'Подработка' },
  { name: 'Алименты' },
  { name: '% по вкладам' },
  { name: 'Дивиденды' },
  { name: 'Помощь родителей' },
  { name: 'Иное' },
];

export const useReferencesStore = defineStore(
  'references',
  () => {
    const categoriesOfComplaints = [
      {
        guid: 'e4ed3169-53a4-11ec-bc90-000c29126e2f',
        code: '012',
        name: 'Запрос проверяющих органов (ССП, Роскомнадзор, Роспотребнадзор, ЦБ (банк России))',
      },
      {
        guid: 'e4ed316a-53a4-11ec-bc90-000c29126e2f',
        code: '009',
        name: 'Жалоба от неустановленного лица(связь жалобщика с конкретным клиентом не установлена, требуется доп.проверка, кроме случаев рекламы о получении займа)',
      },
      {
        guid: 'e4ed316b-53a4-11ec-bc90-000c29126e2f',
        code: '007',
        name: 'Жалоба от клиента',
      },
      {
        guid: 'e4ed316c-53a4-11ec-bc90-000c29126e2f',
        code: '008',
        name: 'Жалоба от 3-го лица',
      },
      {
        guid: 'e4ed316d-53a4-11ec-bc90-000c29126e2f',
        code: '010',
        name: 'Жалоба на рекламу',
      },
      {
        guid: 'e4ed316e-53a4-11ec-bc90-000c29126e2f',
        code: '011',
        name: 'Запрос правоохранительных органов (МВД, ФСБ, СК, Прокуратура)',
      },
    ];

    const resultsOfAppeal = [
      {
        guid: '8f0bd2cf-133c-4630-8123-5c17d0432303',
        code: '000000017',
        name: 'Отказ - есть действ. займ',
      },
      {
        guid: 'df51db93-32cd-4a1b-8466-0163c29a40d5',
        code: '000000006',
        name: 'Нет согласия на обработку',
      },
      {
        guid: '728fa48f-e848-4257-8544-30ae3145125b',
        code: '000000015',
        name: 'Отложенное обращение',
      },
      {
        guid: 'ea36a493-582b-11e8-85bc-000c29a74ca8',
        code: '000000023',
        name: 'Был отказ по проживанию (автоматический отказ)',
      },
      {
        guid: 'e3e8963e-fc96-11ec-85ef-000c29126e2f',
        code: '000000042',
        name: 'Отказался предоставить карту',
      },
      {
        guid: '79a1c3db-1d3d-11eb-8837-000c29126e2f',
        code: '000000033',
        name: 'Отказ клиента (с консультацией) (для проверки)',
      },
      {
        guid: 'cd4157de-f326-11ec-8b40-000c29126e2f',
        code: '000000041',
        name: 'Отказ клиента (без консультации) (автоматический отказ)',
      },
      {
        guid: '3ff5cb3f-aaec-4a6c-8bee-af5c1a77963a',
        code: '000000003',
        name: 'Не подходит по возрасту',
      },
      {
        guid: 'fa273dd5-8c0f-4122-8c2d-408ed92c7fea',
        code: '000000007',
        name: 'Отказ клиента (без консультации)',
      },
      {
        guid: 'be3af078-4dc1-44f6-8f5d-e9f60e0234cd',
        code: '000000019',
        name: 'Не подходит по проживанию (автоматический отказ с сайта)',
      },
      {
        guid: '17a60b16-295b-485d-9068-a30c7627faff',
        code: '000000002',
        name: 'Не подходит по условиям',
      },
      {
        guid: '84ff8daa-bc93-4d7f-90c8-cabfb91a5d81',
        code: '000000014',
        name: 'Обработка прекращена',
      },
      {
        guid: '5e777df9-5f88-11e8-927d-000c29a74ca8',
        code: '000000026',
        name: 'Есть заявка в работе (автоматический отказ)',
      },
      {
        guid: '5e777dfa-5f88-11e8-927d-000c29a74ca8',
        code: '000000027',
        name: 'Отказ по сроку повторного (автоматический отказ)',
      },
      {
        guid: '5e777dfb-5f88-11e8-927d-000c29a74ca8',
        code: '000000028',
        name: 'Был отказ без повторного (автоматический отказ)',
      },
      {
        guid: '5e777dfc-5f88-11e8-927d-000c29a74ca8',
        code: '000000025',
        name: 'Есть действующий займ (автоматический отказ)',
      },
      {
        guid: '5e777dfd-5f88-11e8-927d-000c29a74ca8',
        code: '000000024',
        name: 'Неконтактность (автоматический отказ)',
      },
      {
        guid: '5e777dfe-5f88-11e8-927d-000c29a74ca8',
        code: '000000029',
        name: 'Был списан безнадежный долг (автоматический отказ)',
      },
      {
        guid: '8e5236d6-4cf0-46b0-943f-e85f157f3d35',
        code: '000000009',
        name: 'Нецелевое обращение',
      },
      {
        guid: 'e2b5b56e-ed7a-11eb-9bb2-000c29126e2f',
        code: '000000037',
        name: 'Был списан безнадежный долг',
      },
      {
        guid: 'e2b5b56f-ed7a-11eb-9bb2-000c29126e2f',
        code: '000000038',
        name: 'Отказ - есть заявка в отложенном спросе',
      },
      {
        guid: 'e2b5b570-ed7a-11eb-9bb2-000c29126e2f',
        code: '000000039',
        name: 'Отказ по черному списку РМ',
      },
      {
        guid: 'da6d2195-50ff-4fa9-9cee-b405b52fe355',
        code: '000000012',
        name: 'Был отказ без повторного ',
      },
      {
        guid: 'f5fb7dbe-fc88-11e7-a682-000c29a74ca8',
        code: '000000022',
        name: 'Отказ клиента (с консультацией)',
      },
      {
        guid: '9ca47d8e-3fa1-4de5-a99a-534b3401da8f',
        code: '000000001',
        name: 'Оформлена заявка',
      },
      {
        guid: 'ed8ee01e-2200-4ed2-a9ba-6e6e276bb454',
        code: '000000008',
        name: 'Не оформлена заявка',
      },
      {
        guid: '307635d5-e694-11ec-ac7c-000c29126e2f',
        code: '000000040',
        name: 'Отказ - отсутствует кредитная история',
      },
      {
        guid: '8ad80b08-d520-11eb-ad00-000c29126e2f',
        code: '000000036',
        name: 'Прекращенные (перезвонить)',
      },
      {
        guid: '8ad80b09-d520-11eb-ad00-000c29126e2f',
        code: '000000035',
        name: 'Прекращенные (дозаполнить)',
      },
      {
        guid: '494ee484-ff65-4109-b07e-bc4cbe8cd4f8',
        code: '000000005',
        name: 'Не подходит по прописке',
      },
      {
        guid: 'f4dfcc02-c9b6-41d9-b121-fbfc1fb8fb6a',
        code: '000000020',
        name: 'Отказ по сроку повторного (автоматический отказ с сайта)',
      },
      {
        guid: '694325b2-3999-11eb-b32f-000c29126e2f',
        code: '000000034',
        name: 'Отказ по невозможности видео звонка',
      },
      {
        guid: 'b94fb951-8ee7-4468-b601-939993c2fd46',
        code: '000000011',
        name: 'Повторная заявка',
      },
      {
        guid: '7cd9eabe-4cdb-49f6-b60a-7103b988a942',
        code: '000000018',
        name: 'Отказ - есть заявка в работе',
      },
      {
        guid: '41fd6b5a-5218-4306-b69d-eac5880af348',
        code: '000000004',
        name: 'Не подходит по проживанию',
      },
      {
        guid: '5605cf33-d818-11ea-b788-000c29126e2f',
        code: '000000032',
        name: 'Обработка прекращена (при неперезвоненном обращении)',
      },
      {
        guid: '5605cf34-d818-11ea-b788-000c29126e2f',
        code: '000000031',
        name: 'Обработка прекращена (при недозаполненном обращении)',
      },
      {
        guid: '135fada4-77df-4c24-bbef-69b5d1477858',
        code: '000000013',
        name: 'Заявка оформляется',
      },
      {
        guid: 'cfb6708b-e83c-11e8-bd62-000c29a74ca8',
        code: '000000030',
        name: 'Отложенное ожидает верификации (дистанционная выдача)',
      },
      {
        guid: '7e7a2f20-76d9-417d-bea6-7f25d7b4bdf6',
        code: '000000016',
        name: 'Отказ по сроку повторного',
      },
      {
        guid: 'd84ae198-877a-11e7-bfe4-000c293c8763',
        code: '000000021',
        name: 'Отказ по сроку действия временной регистрации',
      },
    ];

    const categoriesOfNonTargetedAppeals = [
      {
        guid: 'af00f38f-274c-11ec-8cc0-000c29126e2f',
        code: '000000037',
        name: 'Нет гражданства РФ',
      },
      {
        guid: 'c18a5c8e-caa5-11eb-91af-000c29126e2f',
        code: '000000036',
        name: 'Отвечает робот',
      },
      // {
      //   guid: 'b0488e86-4cfd-11e3-9422-3440b59fff32',
      //   code: '000000001',
      //   name: 'Жалоба на смс',
      // },
      {
        guid: 'b0488e87-4cfd-11e3-9422-3440b59fff32',
        code: '000000002',
        name: 'Жалобы прочие',
      },
      {
        guid: 'b0488e88-4cfd-11e3-9422-3440b59fff32',
        code: '000000003',
        name: 'Реклама предложения',
      },
      {
        guid: 'b0488e8a-4cfd-11e3-9422-3440b59fff32',
        code: '000000005',
        name: 'Сотрудник компании',
      },
      {
        guid: 'b0488e8c-4cfd-11e3-9422-3440b59fff32',
        code: '000000007',
        name: 'Прочее',
      },
      {
        guid: '543171c6-280c-11ec-a150-000c29126e2f',
        code: '000000038',
        name: 'Нет видеозвонка',
      },
      {
        guid: '9c88d7be-8996-11e3-a864-3440b59fff30',
        code: '000000010',
        name: 'Скорректировано',
      },
      {
        guid: '51fbdc4c-2b65-11ec-ab34-000c29126e2f',
        code: '000000039',
        name: 'Отказ по условиям, нет постоянной регистрации',
      },
      {
        guid: '89d873fd-6ed8-11e3-add2-3440b59fff30',
        code: '000000008',
        name: 'Задвоенное обращение',
      },
      {
        guid: 'bc91f1ce-713c-11e3-add2-3440b59fff30',
        code: '000000009',
        name: 'Некорректные конт. данные',
      },
      {
        guid: '69b0052f-162e-11e8-b15d-000c29a74ca8',
        code: '000000015',
        name: 'Заявку не оставлял',
      },
      {
        guid: 'e871726f-162e-11e8-b15d-000c29a74ca8',
        code: '000000016',
        name: 'Требуется крупная сумма (61 тыс и более)',
      },
      {
        guid: '63e549a0-25ac-11eb-b69c-000c29126e2f',
        code: '000000030',
        name: 'Негатив',
      },
      {
        guid: 'a5317afa-f891-11e8-bc27-000c29a74ca8',
        code: '000000017',
        name: 'Займ под ПТС',
      },
    ];

    const selectableResultsOfAppeal = resultsOfAppeal.filter((item) =>
      [
        'f5fb7dbe-fc88-11e7-a682-000c29a74ca8',
        'fa273dd5-8c0f-4122-8c2d-408ed92c7fea',
        '41fd6b5a-5218-4306-b69d-eac5880af348',
        '494ee484-ff65-4109-b07e-bc4cbe8cd4f8',
        '3ff5cb3f-aaec-4a6c-8bee-af5c1a77963a',
        '307635d5-e694-11ec-ac7c-000c29126e2f',
      ].includes(item.guid),
    );

    const selectableCategoriesOfNonTargetedAppeals = categoriesOfNonTargetedAppeals.filter(
      (item) => item.guid !== '9c88d7be-8996-11e3-a864-3440b59fff30',
    );

    const selectableOtherResultsOfAppeal = resultsOfAppeal.filter(
      (item) =>
        item.name.match(/отказ/gi) &&
        item.name.match(/^((?!автоматический).)*$/gim) &&
        item.name.match(/^((?!консультац).)*$/gim),
    );

    const complaints = categoriesOfNonTargetedAppeals.filter((item) =>
      [
        'b0488e87-4cfd-11e3-9422-3440b59fff32',
        'b0488e88-4cfd-11e3-9422-3440b59fff32',
        'b0488e8c-4cfd-11e3-9422-3440b59fff32',
        'e871726f-162e-11e8-b15d-000c29a74ca8',
      ].includes(item.guid),
    );

    const maritalStatus = [
      { name: 'Не указан', value: -1 },
      { name: 'Холост/не замужем', value: 0 },
      { name: 'Женат/замужем', value: 1 },
      { name: 'Вдовец/вдова', value: 2 },
    ];

    const debts = [
      { guid: 'Месяц', name: 'Месяц' },
      { guid: 'Неделя', name: 'Неделя' },
      { guid: 'День', name: 'День' },
      { guid: 'Квартал', name: 'Квартал' },
      { guid: 'Год', name: 'Год' },
    ];

    const additionalSources = [
      { name: 'Соц выплаты (пенсии, пособия, стидендия)' },
      { name: 'Сдача в аренду имущества' },
      { name: 'Подработка' },
      { name: 'Алименты' },
      { name: '% по вкладам' },
      { name: 'Дивиденды' },
      { name: 'Помощь родителей' },
      { name: 'Иное' },
    ];

    const postponeReasons = [
      {
        guid: 'Перезвонить',
        code: '7',
        name: 'Перезвонить в...',
      },
      {
        guid: 'Дозаполнить',
        code: '8',
        name: 'Дозаполнить в...',
      },
      {
        guid: 'СбросВызова',
        code: '1',
        name: 'Сброс вызова (сброс после гудков)',
      },
    ];

    const selectablePostponeReasonsByTimer = postponeReasons.filter((item) =>
      ['Перезвонить', 'Дозаполнить'].includes(item.guid),
    );

    const selectablePostponeReasonsByValue = postponeReasons.filter((item) =>
      ['СбросВызова'].includes(item.guid),
    );

    const jobOptions = [
      { text: 'Работает', value: 1 },
      { text: 'Не работает', value: 0 },
      { text: 'Пенсионер', value: 2 },
    ];

    const maritalStatusOptions = [
      { name: 'Не указан', value: -1 },
      { name: 'Холост/не замужем', value: 0 },
      { name: 'Женат/замужем', value: 1 },
      { name: 'Вдовец/вдова', value: 2 },
    ];

    const debtOptions = [
      { guid: 'Месяц', name: 'Месяц' },
      { guid: 'Неделя', name: 'Неделя' },
      { guid: 'День', name: 'День' },
      { guid: 'Квартал', name: 'Квартал' },
      { guid: 'Год', name: 'Год' },
    ];

    const additionalIncomeOptions = additionalIncomeOptionsSource;

    const loanPriority = [
      {
        name: 'актуально',
        value: 0,
      },
      {
        name: 'ОС',
        value: 1,
      },
    ];

    const taskOptions = [
      { name: 'Не отвечает', guid: '6928b89c-c01e-11ea-8d06-000c29126e2f' },
      { name: 'Недоступен', guid: '6928b89c-c01e-11ea-8d06-000c29126e2f' },
      { name: 'Сброс вызова', guid: '6928b89c-c01e-11ea-8d06-000c29126e2f' },
      { name: 'Не интересно', guid: '6928b89b-c01e-11ea-8d06-000c29126e2f' },
      {
        name: 'Неверные конт. данные',
        guid: '6928b898-c01e-11ea-8d06-000c29126e2f',
      },
      {
        name: 'Есть действ. займ',
        guid: '6928b897-c01e-11ea-8d06-000c29126e2f',
      },
      { name: 'Негатив', guid: '6928b899-c01e-11ea-8d06-000c29126e2f' },
      {
        name: 'Отвечает робот',
        guid: '51d4949c-747c-11ec-9429-000c29126e2f',
      },
    ];

    const productPaymentFrequency = [
      {
        id: -1,
        value: 'не указано',
      },
      {
        id: 0,
        value: 'периодично',
      },
      {
        id: 1,
        value: 'разово',
      },
    ];

    const productPaymentPeriodicity = [
      {
        id: -1,
        value: 'не указано',
      },
      {
        id: 0,
        value: 'день',
      },
      {
        id: 1,
        value: 'неделя',
      },
      {
        id: 2,
        value: 'месяц',
      },
      {
        id: 3,
        value: 'квартал',
      },
      {
        id: 4,
        value: 'год',
      },
    ];

    const getProductPaymentPeriodicity = ({ value, periodicity }) => {
      try {
        if (periodicity === -1) return '-';

        const numWord = (val, words) => {
          const tmp = Math.abs(val) % 100;
          const num = tmp % 10;
          if (tmp > 10 && tmp < 20) return words[2];
          if (num > 1 && num < 5) return words[1];
          if (num === 1) return words[0];
          return words[2];
        };

        const mapping = {
          0: ['день', 'дня', 'дней'],
          1: ['неделя', 'недели', 'недель'],
          2: ['месяц', 'месяца', 'месяцев'],
          3: ['квартал', 'квартала', 'кварталов'],
          4: ['год', 'года', 'лет'],
        };

        return numWord(value, mapping[periodicity]);
      } catch (error) {
        return '-';
      }
    };

    return {
      categoriesOfComplaints,
      resultsOfAppeal,
      categoriesOfNonTargetedAppeals,
      selectableResultsOfAppeal,
      selectableCategoriesOfNonTargetedAppeals,
      selectableOtherResultsOfAppeal,
      complaints,
      maritalStatus,
      debts,
      additionalSources,
      postponeReasons,
      selectablePostponeReasonsByTimer,
      selectablePostponeReasonsByValue,
      jobOptions,
      maritalStatusOptions,
      debtOptions,
      additionalIncomeOptions,
      loanPriority,
      taskOptions,
      productPaymentFrequency,
      productPaymentPeriodicity,
      getProductPaymentPeriodicity,
    };
  },
  {
    persist: {
      storage: localStorage,
    },
  },
);
