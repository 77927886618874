import { useAuthStore } from './auth';
import { useDocumentStore } from './document';

export const useAppStore = defineStore(
  'app',
  () => {
    const route = useRoute();
    const step = ref(1);
    const theme = ref('light');
    const appealInfoIsLoading = ref(false);
    const personalDataAccepted = ref(null);
    const documentShortInfoIsVisible = ref(true);
    const appealStatus = ref('');
    const UpdateTaskActivityInterval = ref('');
    const abortController = ref(null);

    const toStep = function (value) {
      const _step = Number(value);
      if (![1, 2].includes(_step)) return;
      this.$patch({
        documentShortInfoIsVisible: _step === 1,
        step: _step,
      });
    };

    const isPageCreate = computed(() => route.name === 'create');

    const isPageDeferred = computed(() => route.name === 'deferred');

    const isPageTypeTask = computed(() => route.query.type === 'task');

    const toStep2IsBanned = computed(() => {
      const { availability_of_credit_history, availability_of_video } = useDocumentStore();

      return (
        step.value === 2 ||
        requestIsBanned.value ||
        availability_of_credit_history < 1 ||
        availability_of_video < 1
      );
    });

    const UpdateTaskActivity = ({ trigger = true }) => {
      const route = useRoute();
      const authStore = useAuthStore();

      if (!authStore.isReallyLoggedIn) {
        if (UpdateTaskActivityInterval.value) {
          clearInterval(UpdateTaskActivityInterval.value);
        }

        return;
      }

      if (!trigger && UpdateTaskActivityInterval.value) {
        return clearInterval(UpdateTaskActivityInterval.value);
      }

      const { $ws } = useNuxtApp();

      if (UpdateTaskActivityInterval.value) {
        clearInterval(UpdateTaskActivityInterval.value);
      }

      if (trigger && route.query.call_guid) {
        UpdateTaskActivityInterval.value = setInterval(() => {
          $ws.sockets.main.emit('update-task-activity', {
            call_guid: route.query.call_guid,
            document_guid: route.query.document_guid,
            document_name: route.query.document_name,
            user_guid: authStore.user.user_guid,
          });
        }, 60000);
      }
    };

    const appTheme = computed(() => {
      const appCookie = useCookie('app');
      return appCookie?.value?.theme ? appCookie.value.theme : theme.value;
    });

    const requestIsBanned = ref(null);

    return {
      theme,
      appealInfoIsLoading,
      step,
      personalDataAccepted,
      documentShortInfoIsVisible,
      toStep,
      isPageCreate,
      isPageDeferred,
      isPageTypeTask,
      appealStatus,
      UpdateTaskActivity,
      appTheme,
      requestIsBanned,
      abortController,
      toStep2IsBanned,
    };
  },
  {
    persist: {
      paths: ['theme'],
    },
  },
);
