import { defineNuxtPlugin } from '#app';
import * as Sentry from '@sentry/vue';
import * as pkg from '~/package.json';

export default defineNuxtPlugin((nuxtApp) => {
  const release = pkg.version;
  const {
    public: { sentry },
  } = useRuntimeConfig();
  const router = useRouter();

  if (sentry.environment !== 'production') console.log(sentry.dsn, { release }, sentry.environment);

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    release,
    environment: sentry.environment,

    trackComponents: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    integrations: [
      // new Sentry.BrowserTracing({
      //   routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      //   tracePropagationTargets: ['localhost', /^\//],
      // }),
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        blockAllMedia: false,
        useCompression: false,
        maskAllInputs: false,
        maskAllText: false,
        networkDetailAllowUrls: ['localhost:8000', /^https:\/\/api-arm\.denginadom\.ru\//],
      }),
      Sentry.metrics.metricsAggregatorIntegration(),
    ],

    tracePropagationTargets: ['localhost:8000', /^https:\/\/api-arm\.denginadom\.ru\//],

    tracesSampleRate: 1, // Change in prod

    replaysSessionSampleRate: 0.1, // Change in prod
    replaysOnErrorSampleRate: 1.0, // Change in prod if necessary

    debug: false,

    // The following enables exeptions to be logged to console despite logErrors being set to false (preventing them from being passed to the default Vue err handler)
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        // eslint-disable-next-line
        console.error(`[sentry]: (${hint.originalException})`, { event, hint });
      }
      // Continue sending to Sentry
      return event;
    },
  });

  try {
    const storage = useCookie('auth');
    if (storage && storage.value) {
      Sentry.setUser({
        username: storage.value.user?.user_name,
        id: storage.value.user?.user_guid,
      });
    }
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
  }

  return {
    provide: {
      sentry: {
        setContext: (n, context) => Sentry.setContext(n, context),
        setUser: (user) => Sentry.setUser(user),
        setTag: (tagName, value) => Sentry.setTag(tagName, value),
        addBreadcrumb: (breadcrumb) => Sentry.addBreadcrumb(breadcrumb),
        captureException: (...args) => Sentry.captureException(...args),
      },
    },
  };
});
