import { io } from 'socket.io-client';

export default defineNuxtPlugin(() => {
  const sockets = {};
  const config = useRuntimeConfig();

  const defaultSocketHandler = (socket) => {
    socket.on('connect', () => {
      /* eslint-disable-next-line */
      console.log({ message: '[socket.io] connected', socket });
    });

    socket.on('disconnect', () => {
      /* eslint-disable-next-line */
      console.log({ message: '[socket.io] disconnected' });
    });
  };

  const connect = (url, label, settings = null) => {
    return new Promise((resolve, reject) => {
      if (!sockets[label]) {
        try {
          sockets[label] = io(url, settings);
          resolve(sockets[label]);
        } catch (e) {
          reject(e);
        }
      } else {
        resolve(sockets[label]);
      }
    });
  };

  const clear = () => {
    Object.keys(sockets).forEach((label) => {
      try {
        sockets[label].disconnect();
        delete sockets[label];
      } catch (err) {
        // eslint-disable-next-line
        console.log(err);
      }
    });
  };

  const useConnection = async ({
    url = config.public.wssUrl,
    label = 'main',
    settings = { secure: true },
    handler = defaultSocketHandler,
  }) => {
    try {
      if (!url) return;

      const socket = await connect(url, label, settings);
      if (handler) handler(socket);

      return socket;
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
      return {};
    }
  };

  return {
    provide: {
      ws: {
        sockets,
        connect,
        clear,
        useConnection,
      },
    },
  };
});
