import { useNotieStore } from './notifications';

export const pages = {
  login: '/login',
  index: '/',
};

export const settings = {
  login: {
    fields: {
      login: 'user_code',
      password: 'user_pass',
    },
  },
};

export const authFieldsMap = {
  user: 'data',
};

export const authFreeRoutes = ['/redirect', pages.login];

export const useAuthStore = defineStore(
  'auth',
  () => {
    const router = useRouter();
    const { $api, $sentry } = useNuxtApp();

    const isLoggedIn = ref(false);
    const user = ref({});
    const lastVisited = ref(null);

    const reset = () => {
      isLoggedIn.value = false;
      user.value = {};
      lastVisited.value = null;
    };

    const login = async (payload) => {
      try {
        const { data } = await $api.web.Login({
          [settings.login.fields.login]: payload[settings.login.fields.login],
          [settings.login.fields.password]: payload[settings.login.fields.password],
        });

        const notieStore = useNotieStore();
        notieStore.remove({ id: 'armApiError' });

        if (!data?.data?.data?.user_guid) {
          throw new Error('Невозможно авторизоваться');
        }

        Object.assign(user.value, { user_guid: data.data.data.user_guid });

        if (Number(data.data.data.pass_update_required) !== 1) {
          user.value = data.data[authFieldsMap.user];
          isLoggedIn.value = true;
          $sentry.setUser({ id: user.value.user_guid, username: user.value.user_name });
          if (payload.redirect) {
            router.push(lastVisited.value ?? pages.index);
          }
        }

        return data.data.data;
      } catch (err) {
        reset();
        throw err;
      }
    };

    const logout = async () => {
      try {
        await $api.web.Logout();
      } catch (err) {
        // eslint-disable-next-line
        console.log(err);
      } finally {
        reset();
        router.push(pages.login);
      }
    };

    const isReallyLoggedIn = computed(() => {
      return Object.keys(user.value).length && isLoggedIn.value;
    });

    return {
      settings,
      isLoggedIn,
      login,
      logout,
      reset,
      user,
      isReallyLoggedIn,
      lastVisited,
    };
  },
  {
    persist: {
      storage: persistedState.cookiesWithOptions({
        maxAge: 60 * 60 * 12,
      }),
    },
  },
);
