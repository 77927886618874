import { settings } from '~/stores/auth';
import isNotEmpty from '~/helpers/isNotEmpty';

const isString = (value) => Object.prototype.toString.call(value) === '[object String]';

const makePayload = (rawPayload) => {
  const isEmpty1cValue = (value) => {
    try {
      if (!isString(value)) return false;
      return ['00.01.0101', '0001-01-01T00:00:00', '00000000-0000-0000-0000-000000000000'].includes(
        value,
      );
    } catch (err) {
      return false;
    }
  };

  const payload = Object.fromEntries(
    Object.keys(rawPayload)
      .filter((key) => isNotEmpty(rawPayload[key]) && !isEmpty1cValue(rawPayload[key]))
      .map((key) => [key, rawPayload[key]]),
  );

  return payload;
};

export default (instance) => {
  return {
    Login(data) {
      return instance().post('/Login', {
        [settings.login.fields.login]: data[settings.login.fields.login],
        [settings.login.fields.password]: data[settings.login.fields.password],
      });
    },

    Logout() {
      return instance().get('/Logout');
    },

    ClearTasks() {
      return instance().get('/ClearTasks');
    },

    GetDocumentData({
      document_name = 'РегистрацияОбращения',
      document_guid,
      to_block_document = 0,
      call_guid,
      start_task_on_arm,
    }) {
      return instance().get('GetDocumentData', {
        params: { document_name, document_guid, to_block_document, call_guid, start_task_on_arm },
      });
    },

    GetIncomingCallData({ document_guid, call_guid, start_task_on_arm, user_guid }) {
      return instance().get('GetIncomingCallData', {
        params: {
          document_guid,
          call_guid,
          start_task_on_arm,
          user_guid,
        },
      });
    },

    GetDeferredComments({
      document_name = 'РегистрацияОбращения',
      document_guid,
      to_block_document = 0,
    }) {
      return instance().get('/GetDeferredComments', {
        params: { document_name, document_guid, to_block_document },
      });
    },

    GetReportData({ period_option = 0 }) {
      return instance().get('/GetReportData', {
        params: { period_option, binary: 1 },
      });
    },

    GetDeferredAppeals({
      filter_channel_of_receipt = 0,
      filter_pending_states = 0,
      filter_prescription_of_deferred = 3,
      filter_only_own = 0,
    }) {
      return instance().get('/GetDeferredAppeals', {
        params: {
          filter_channel_of_receipt,
          filter_pending_states,
          filter_prescription_of_deferred,
          filter_only_own,
        },
      });
    },

    StopListSMSCheck({ phone }) {
      return instance().get('/StopListSMSCheck', {
        params: { phone },
      });
    },

    StopListIncomingCallsCheck({ phone }) {
      return instance().get('/StopListIncomingCallsCheck', {
        params: { phone },
      });
    },

    StopListSMSAdd({ phone }) {
      return instance().get('/StopListSMSAdd', {
        params: { phone },
      });
    },

    StopListIncomingCallsAdd({ phone }) {
      return instance().get('/StopListIncomingCallsAdd', {
        params: { phone },
      });
    },

    StopListSMSDelete({ phone }) {
      return instance().get('/StopListSMSDelete', {
        params: { phone },
      });
    },

    StopListIncomingCallsDelete({ phone }) {
      return instance().get('/StopListIncomingCallsDelete', {
        params: { phone },
      });
    },

    GetReferenceData({
      reference_name,
      search_line,
      comparison_method,
      count,
      sorting,
      include_deleted,
    }) {
      return instance().get('/GetReferenceData', {
        params: {
          reference_name,
          search_line,
          comparison_method,
          count,
          sorting,
          include_deleted,
        },
      });
    },

    GetPreviousAppealsRequests({
      search_line,
      counterparty_guid,
      birthday,
      phone,
      passport_serie,
      passport_number,
      passport_old_serie,
      passport_old_number,
      count,
      use_condition_or,
      return_only_last_requests,
    }) {
      return instance().get('/GetPreviousAppealsRequests', {
        params: {
          search_line,
          counterparty_guid,
          birthday,
          phone,
          passport_serie,
          passport_number,
          passport_old_serie,
          passport_old_number,
          count,
          return_only_requests: 1,
          use_condition_or,
          return_only_last_requests,
        },
      });
    },

    GetInfo4Appeal(
      {
        changed_field,
        previous_request_guid,
        document_date,
        request_date,
        cityKLADR_residence_kladr_id,
        cityKLADR_registration_kladr_id,
        cityKLADR_temp_registration_kladr_id,
        cityKLADR_residence_geo_lat,
        cityKLADR_residence_geo_lon,
        age,
        temp_registration_date_from,
        temp_registration_date_to,
        request_guid,
        adressKLADR_residence_kladr_id,
        adressKLADR_residence_geo_lat,
        adressKLADR_residence_geo_lon,
        adressKLADR_residence_region_with_type,
        adressKLADR_residence_area_with_type,
        adressKLADR_residence_city_with_type,
        adressKLADR_residence_settlement_with_type,
        adressKLADR_residence_street_with_type,
        adressKLADR_residence_house,
        adressKLADR_residence_block,
        availability_of_video,
        last_name,
        first_name,
        secondary_name,
        secondary_name_is_absent,
        previous_last_name,
        birthday,
        sex,
        borrower_guid,
        subdivision_guid,
      },
      options = null,
    ) {
      return instance().post(
        '/GetInfo4Appeal',
        makePayload({
          changed_field,
          previous_request_guid,
          document_date,
          request_date,
          cityKLADR_residence_kladr_id,
          cityKLADR_registration_kladr_id,
          cityKLADR_temp_registration_kladr_id,
          cityKLADR_residence_geo_lat,
          cityKLADR_residence_geo_lon,
          age,
          temp_registration_date_from,
          temp_registration_date_to,
          request_guid,
          adressKLADR_residence_kladr_id,
          adressKLADR_residence_geo_lat,
          adressKLADR_residence_geo_lon,
          adressKLADR_residence_region_with_type,
          adressKLADR_residence_area_with_type,
          adressKLADR_residence_city_with_type,
          adressKLADR_residence_settlement_with_type,
          adressKLADR_residence_street_with_type,
          adressKLADR_residence_house,
          adressKLADR_residence_block,
          availability_of_video,
          last_name,
          first_name,
          secondary_name,
          secondary_name_is_absent,
          previous_last_name,
          birthday,
          sex,
          borrower_guid,
          subdivision_guid,
        }),
        options,
      );
    },

    GetRequestInfo(request_guid) {
      return instance().get('/GetRequestInfo', {
        params: { request_guid, binary: 1 },
      });
    },

    UpdateUserInfo({ user_old_pass, user_new_pass }) {
      return instance().post('/UpdateUserInfo', {
        user_old_pass,
        user_new_pass,
      });
    },

    SaveDocumentData({ payload, document_name = 'РегистрацияОбращения' }) {
      return instance().post(`/SaveDocumentData?document_name=${document_name}`, payload);
    },

    LkSaveDocumentData({ payload }) {
      return instance().post('/LkSaveDocumentData', payload);
    },

    CreateShortLink({ brand_guid, phone, request_guid }) {
      return instance().post('/CreateShortLink', {
        brand_guid,
        phone,
        request_guid,
      });
    },

    SetShortLinkForLk({ brand_guid, short_link, request_guid }) {
      return instance().post('/SetShortLinkForLk', {
        brand_guid,
        short_link,
        request_guid,
      });
    },

    CheckResidence(kladr_id) {
      return instance().get(`/CheckResidence?kladr_id=${kladr_id}`);
    },

    GetClientTime(timezone) {
      return instance().get(`/GetClientTime?tz=${timezone}`);
    },

    CheckPassport({
      passport_serie,
      passport_number,
      passport_date,
      birthday,
      passport_old_is_present,
    }) {
      return instance().post(`/CheckPassport`, {
        passport_serie,
        passport_number,
        passport_date,
        birthday,
        passport_old_is_present,
      });
    },

    GetSimilarNames({ source, target }) {
      return instance().post(`/GetSimilarNames`, { source, target });
    },

    address(payload) {
      return instance().post('/suggest/address', payload);
    },

    fio(payload) {
      return instance().post('/suggest/fio', payload);
    },

    fmsUnit(payload) {
      return instance().post('/suggest/fms_unit', payload);
    },
  };
};
