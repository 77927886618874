import axios from 'axios';
import { useAuthStore } from '~/stores/auth';
import { useDocumentStore } from '~/stores/document';
import { useModalStore } from '~/stores/modal';

const instances = {
  web: () => {
    const config = useRuntimeConfig();
    const authStore = useAuthStore();
    const documentStore = useDocumentStore();

    const encoder = new TextEncoder();

    const instance = axios.create({
      baseURL: config.public.baseURL,
      headers: {
        'user-guid': authStore.user?.user_guid,
        'brand-guid': btoa(
          String.fromCharCode.apply(null, encoder.encode(documentStore.brand_guid)),
        ),
      },
      withCredentials: true,
    });

    const createAxiosResponseInterceptor = () => {
      const interceptor = instance.interceptors.response.use(
        (response) => response,
        (err) => {
          if (
            (err?.response?.status &&
              err?.response?.status !== 401 &&
              err?.response?.status !== 403) ||
            err.code === 'ERR_CANCELED'
          ) {
            return Promise.reject(err);
          }

          instance.interceptors.response.eject(interceptor);
          const modalStore = useModalStore();
          modalStore.login.visible = true;

          return Promise.reject(err);
        },
      );
    };

    createAxiosResponseInterceptor();
    return instance;
  },
};

export default instances;
