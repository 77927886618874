import { useAuthStore, pages, authFreeRoutes } from '~/stores/auth';

export default defineNuxtRouteMiddleware((to, from) => {
  try {
    const authStore = useAuthStore();
    const permittedRoutes = [pages.login];

    const handlePermittedRoutes = () => {
      if (permittedRoutes.includes(to.path) && authStore.isReallyLoggedIn) {
        return navigateTo(authStore.lastVisited.value || to.fullPath);
      }

      if (
        from.path !== pages.login &&
        !permittedRoutes.includes(to.path) &&
        !authStore.isReallyLoggedIn
      ) {
        return navigateTo(pages.login);
      }
    };

    // skip auth free routes
    if (authFreeRoutes.includes(to.path)) {
      if (permittedRoutes.includes(to.path) && authStore.isReallyLoggedIn) {
        return navigateTo(authStore.lastVisited.value || pages.index);
      }

      return;
    }

    // use storage
    const stored = useCookie('auth');
    if (!authStore || !stored || !stored.value) {
      return navigateTo(pages.login);
    }

    // save info about last visited route
    if (authStore.$patch && from.path !== pages.login) {
      authStore.$patch({
        lastVisited: {
          path: from.path,
          query: from.query,
        },
      });
    }

    return handlePermittedRoutes();
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
  }
});
