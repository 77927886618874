import { AxiosError } from 'axios';
import { capitalize } from 'vue';
import { useAuthStore } from '~/stores/auth';
import { useDocumentStore } from '~/stores/document';
import { useNotieStore } from '~/stores/notifications';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error, context) => {
    const authStore = useAuthStore();
    const documentStore = useDocumentStore();
    const notieStore = useNotieStore();
    const { $sentry } = useNuxtApp();

    const handleAxiosError = (err) => {
      if (error instanceof AxiosError) {
        return err?.response?.data?.data?.error
          ? 'Ошибка! ' + err?.response?.data?.data?.error
          : 'Непредвиденная ошибка';
      }

      return err.message;
    };

    const fieldsToCheck = error?.response?.data?.data?.fields_to_check;

    if (fieldsToCheck) {
      documentStore.$patch({
        fields_to_check: fieldsToCheck.map((item) => Object.keys(item)),
      });
    }

    const errorMapping = {
      400: (err) => handleAxiosError(err),
      401: () => {
        return 'Ошибка авторизации. Необходимо заново войти в АРМ';
      },
      403: () => {
        return 'Ошибка авторизации. Доступ запрещен';
      },
      500: (err) => handleAxiosError(err),
    };

    const createErrorNotification = (err) => {
      if (
        !(err instanceof AxiosError) ||
        !err?.response?.status ||
        !errorMapping[err?.response?.status]
      ) {
        notieStore.add({
          type: 'error',
          message: err.message,
        });

        return;
      }

      notieStore.forcePatch({
        id: 'armApiError',
        type: 'error',
        message: errorMapping[err?.response?.status](err) || err.message,
      });

      const fieldsToCheck = err.response?.data?.data?.fields_to_check || [];

      fieldsToCheck.forEach((item) => {
        const entry = Object.entries(item);

        notieStore.forcePatch({
          id: `validation${capitalize(entry[0][0])}`,
          type: 'error',
          sticky: true,
          message: entry[0][1],
        });
      });
    };

    createErrorNotification(error);

    // eslint-disable-next-line
    console.log(error, context);
    $sentry.captureException(error, {
      user: { username: authStore?.user?.user_name, id: authStore?.user?.user_guid },
    });
  };
});
