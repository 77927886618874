import { useDocumentStore } from './document';

const initialConfirm = {
  dynamicComponent: '',
  visible: false,
  title: 'Вы уверены?',
  size: 'fit',
  closeOptions: ['esc', 'click-outside'],
  trueText: 'Да',
  trueLoading: false,
  falseText: 'Нет',
  customClass: '',
  message: '',
  trueCallback: () => null,
  falseCallback: () => null,
  closeCallback: () => null,
  trueDisabled: ref(false),
};

const defaultPhoneManagementMapping = {
  StopListSMS: 'Стоп-лист SMS',
  StopListIncomingCalls: 'Черный список входящих вызовов',
};

const initialPhoneManagement = {
  visible: false,
  title: defaultPhoneManagementMapping.StopListSMS,
  selected: 'StopListSMS',
};

export const useModalStore = defineStore(
  'modal',
  () => {
    const store = useModalStore();

    const report = reactive({
      visible: false,
      remotePdfDocument: '',
      reset() {
        Object.assign(report, { visible: false, remotePdfDocument: '' });
      },
    });

    const phoneManagement = reactive({
      visible: false,
      title: initialPhoneManagement.title,
      selected: initialPhoneManagement.selected,
      reset() {
        Object.assign(phoneManagement, initialPhoneManagement);
      },
      select(action) {
        if (!Object.keys(defaultPhoneManagementMapping).includes(action)) return;
        this.visible = true;
        this.title = defaultPhoneManagementMapping[action];
        this.selected = action;
      },
      listActions() {
        return Object.keys(defaultPhoneManagementMapping).map((key) => ({
          title: defaultPhoneManagementMapping[key],
          id: key,
        }));
      },
    });

    const confirm = reactive({
      ...initialConfirm,
      create: ({
        dynamicComponent,
        trueCallback,
        trueText = confirm.trueText,
        falseCallback,
        falseText = confirm.falseText,
        title = confirm.title,
        size = confirm.size,
        closeCallback,
        customClass = confirm.customClass,
        trueDisabled = ref(false),
        message,
      }) => {
        const promise = new Promise((resolve) => {
          Object.assign(confirm, {
            ...confirm,
            visible: true,
            trueCallback: async () => {
              try {
                confirm.trueLoading = true;
                // confirm.trueDisabled = true;
                if (trueCallback) await trueCallback();
                confirm.finishCallback(true);
              } catch (err) {
                confirm.trueLoading = false;
                // confirm.trueDisabled = false;
                throw err;
              }
            },
            trueText,
            falseCallback: async () => {
              if (falseCallback) await falseCallback();
              confirm.finishCallback(false);
            },
            falseText,
            finishCallback(res = false) {
              confirm.closeCallback && confirm.closeCallback();
              Object.assign(confirm, initialConfirm);
              resolve(res);
            },
            title,
            size,
            dynamicComponent,
            closeCallback,
            customClass,
            message,
            trueDisabled,
          });
        });

        return promise;
      },
    });

    const deferredAppealComments = reactive({
      visible: false,
      documentGuid: '',
      reset() {
        Object.assign(deferredAppealComments, { visible: false, documentGuid: '' });
      },
      open(documentGuid) {
        Object.assign(deferredAppealComments, { visible: true, documentGuid });
      },
    });

    const specifyAppeal = reactive({
      visible: false,
    });

    const complaint = reactive({
      guid: '',
      name: '',
      reset() {
        const documentStore = useDocumentStore();
        this.guid = '';
        this.name = '';
        documentStore.$patch({
          category_of_complaint_guid: '',
          document_comment: '',
        });
      },
    });

    const postponement = reactive({
      comment: '',
      guid: '',
      name: '',
      date_of_chime: '',
      utc: '',
      data: {
        day: new Date(),
        time: {
          hours: 0,
          minutes: 0,
          seconds: 0,
        },
        clientTime: '',
        minHour: 0,
        minMinute: 0,
      },
      reset() {
        this.comment = '';
        this.guid = '';
        this.name = '';
        this.date_of_chime = '';
        this.utc = '';
        this.data = {
          day: new Date(),
          time: {
            hours: 0,
            minutes: 0,
            seconds: 0,
          },
          clientTime: '',
          minHour: 0,
          minMinute: 0,
        };
      },
    });

    const postponementData = reactive({
      day: new Date(),
      time: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      clientTime: '',
      minHour: 0,
      minMinute: 0,
    });

    const login = reactive({
      visible: false,
    });

    const requestInfo = reactive({
      visible: false,
      request_guid: '',
      remotePdfDocument: '',
      reset() {
        Object.assign(requestInfo, { visible: false, remotePdfDocument: '', request_guid: '' });
      },
    });

    const addComment = reactive({
      visible: false,
      comment: '',
    });

    const open = function (key) {
      Object.assign(this[key], { visible: true });
    };

    const close = function (key) {
      Object.assign(this[key], { visible: false });
    };

    const toggle = function (key) {
      Object.assign(this[key], { visible: !this[key].visible });
    };

    const hasVisibleModals = computed(() => {
      return (
        Object.keys(store.$state)
          .map((key) => store.$state[key].visible)
          .filter((item) => item).length > 0
      );
    });

    return {
      confirm,
      report,
      phoneManagement,
      specifyAppeal,
      deferredAppealComments,
      complaint,
      postponement,
      login,
      requestInfo,
      addComment,
      open,
      close,
      toggle,
      hasVisibleModals,
      postponementData,
    };
  },
  {
    persist: false,
  },
);
