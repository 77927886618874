import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import sentry_PZVGm6LH3G from "/app/plugins/sentry.js";
import socketIO_MnxRrNVM5O from "/app/plugins/socketIO.js";
import maska_r8BHBbSmHt from "/app/plugins/maska.js";
import index_VHbHwe8vN1 from "/app/plugins/api/index.js";
import errorHandler_cfkZ75xwSQ from "/app/plugins/errorHandler.js";
import radialProgress_87nXoPTR8u from "/app/plugins/radialProgress.js";
import plugin_1UohGbtF8v from "/app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import pinia_reset_sqoViyn2LZ from "/app/plugins/pinia-reset.js";
import vue3pdf_wd5dSG7yyD from "/app/plugins/vue3pdf.js";
export default [
  plugin_vue3_A0OWXRrUgq,
  revive_payload_client_4sVQNw7RlN,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  sentry_PZVGm6LH3G,
  socketIO_MnxRrNVM5O,
  maska_r8BHBbSmHt,
  index_VHbHwe8vN1,
  errorHandler_cfkZ75xwSQ,
  radialProgress_87nXoPTR8u,
  plugin_1UohGbtF8v,
  pinia_reset_sqoViyn2LZ,
  vue3pdf_wd5dSG7yyD
]