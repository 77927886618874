import pick from 'lodash.pick';
import mapAddress1C from './mapAddress1C';
import prepare1Cvalues from './prepare1Cvalues';
import prepareNames from './prepareNames';
import dayjs from './dayjs';

const prepareAddresses = ({ from, to, container }) => {
  const keysFrom = mapAddress1C(from);
  const res = {};
  keysFrom.forEach((key) => {
    res[key] = res[key.replace(from, to)] = container[key];
  });

  return res;
};

export default (data) => {
  const updatedAppeal = pick(data, [
    ...mapAddress1C('cityKLADR_residence'),
    ...mapAddress1C('cityKLADR_registration'),
    ...mapAddress1C('cityKLADR_temp_registration'),
    ...mapAddress1C('adressKLADR_residence'),
    ...mapAddress1C('adressKLADR_registration'),
    ...mapAddress1C('adressKLADR_temp_registration'),
    ...mapAddress1C('adressKLADR_work'),
    'borrower_guid',
    'fio',
    'full_name',
    'birthday',
    'phone',
    'mobile_phone',
    'duration_of_residence_years',
    'duration_of_residence_months',
    'sex',
    'cityKLADR_residence_geo_lat',
    'cityKLADR_residence_geo_lon',
    'adressKLADR_residence_geo_lat',
    'adressKLADR_residence_geo_lon',
    'adressKLADR_registration_geo_lat',
    'adressKLADR_registration_geo_lon',
    'passport_serie',
    'passport_number',
    'passport_devcode',
    'passport_date',
    'passport_issuedby',
    'passport_old_serie',
    'passport_old_number',
    'passport_old_devcode',
    'passport_old_date',
    'passport_old_issuedby',
  ]);

  Object.assign(updatedAppeal, prepare1Cvalues(updatedAppeal));

  // Возраст
  if (data.birthday) {
    updatedAppeal.age = dayjs().diff(dayjs(data.birthday), 'year');
  }

  // ФИО из обращения
  if (data.fio) {
    Object.assign(updatedAppeal, prepareNames(data.fio));
  }

  // ФИО из обращения
  if (data.full_name) {
    Object.assign(updatedAppeal, prepareNames(data.full_name));
  }

  if (!updatedAppeal.cityKLADR_residence_kladr_id && updatedAppeal.adressKLADR_residence_kladr_id) {
    Object.assign(
      updatedAppeal,
      prepareAddresses({
        from: 'adressKLADR_residence',
        to: 'cityKLADR_residence',
        container: updatedAppeal,
      }),
    );
  }

  if (
    !updatedAppeal.cityKLADR_registration_kladr_id &&
    updatedAppeal.adressKLADR_registration_kladr_id
  ) {
    Object.assign(
      updatedAppeal,
      prepareAddresses({
        from: 'adressKLADR_registration',
        to: 'cityKLADR_registration',
        container: updatedAppeal,
      }),
    );
  }

  if (
    !updatedAppeal.cityKLADR_temp_registration_kladr_id &&
    updatedAppeal.adressKLADR_temp_registration_kladr_id
  ) {
    Object.assign(
      updatedAppeal,
      prepareAddresses({
        from: 'adressKLADR_temp_registration',
        to: 'cityKLADR_temp_registration',
        container: updatedAppeal,
      }),
    );
  }

  if (!updatedAppeal.cityKLADR_residence_kladr_id && updatedAppeal.adressKLADR_residence_kladr_id) {
    Object.assign(
      updatedAppeal,
      prepareAddresses({
        from: 'adressKLADR_residence',
        to: 'cityKLADR_residence',
        container: updatedAppeal,
      }),
    );
  }

  return updatedAppeal;
};
